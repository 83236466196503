import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store/index'
import transformationSession from '@/services/transformationSession'
import { load, save } from '@/utils/localStorage'

@Module({ generateMutationSetters: true })
class ImpersonationModule extends VuexModule {
  _isOpen = false
  _hasTransformationSession: boolean = load('hasTransformationSession') || false

  /**
   * Returns whether the impersonation menu is open.
   * @returns A boolean indicating whether the impersonation menu is open.
   */
  get isOpen(): boolean {
    return this._isOpen
  }

  /**
   * Returns whether there is an active transformation session.
   * @returns A boolean indicating whether there is an active transformation session.
   */
  get hasTransformationSession(): boolean {
    return this._hasTransformationSession
  }

  /**
   * Opens the impersonation menu.
   */
  @Action
  open(): void {
    this._isOpen = true
  }

  /**
   * Closes the impersonation menu.
   */
  @Action
  close(): void {
    this._isOpen = false
  }
  /**
   * Fetches whether there is an active transformation session.
   */
  @Action
  async fetchHasTransformationSession(): Promise<void> {
    this._hasTransformationSession =
      await transformationSession.hasActiveSession()
    save('hasTransformationSession', this._hasTransformationSession)
  }

  /**
   * Stops the transformation session.
   */
  @Action
  async stopTransformationSession(): Promise<void> {
    await transformationSession.stop()
    await this.fetchHasTransformationSession()
    window.location.reload()
  }
}

export default new ImpersonationModule({ store, name: 'impersonation' })
