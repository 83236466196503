import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { bffProxyUrl } from '@/utils/env'
import { ApiResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Checks if there is an active transformation session.
   *
   * @returns a boolean indicating whether there is an active transformation session.
   */
  async hasActiveSession(): Promise<boolean> {
    const url = `${bffProxyUrl}/transformationSession/hasActiveSession`
    const response: AxiosResponse<ApiResult> = await httpService.get(url)
    return response.data.successful === true
  },
  /**
   * Checks if the user has transformation privileges.
   *
   * @param params - An object containing the parameters to use for the transformation validation.
   * @returns a boolean indicating whether the user has transformation privileges.
   */
  async canTransform(params): Promise<boolean> {
    const url = `${bffProxyUrl}/transformationSession/validateTransformation`
    const response = await httpService
      .post(url, params.payload)
      .catch((e) => e.response)
    return response.data.successful === true
  },
  /**
   * Ends the active transformation session.
   */
  stop(): Promise<unknown> {
    const url = `${bffProxyUrl}/transformationSession/stop`
    return httpService.get(url)
  },
  /**
   * Starts a new transformation session.
   *
   * @param params - An object containing the parameters to use for starting the transformation session.
   */
  start(params): Promise<unknown> {
    const url = `${bffProxyUrl}/transformationSession/start`
    return httpService.post(url, params.payload).catch((e) => e.response)
  },
}
