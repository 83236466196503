<template>
  <div>
    <div class="d-inline-block">
      <div class="pie-wrapper" :style="wrapperStyles">
        <span v-if="value !== null && value >= 0">
          <div class="dot-container" :style="dotContainerStyles">
            <div class="dot" :style="dotStyles" />
          </div>
          <div class="needle" :style="needleStyles" />
        </span>
        <svg :width="width" :height="width * 1.1" class="position-absolute z-2">
          <defs>
            <mask id="strokeMask">
              <path
                :d="createArcPath({ start: min, end: max, color: 'black' })"
                :stroke-width="weight"
                fill="none"
                stroke="white"
                stroke-linecap="round"
                stroke-opacity="100%"
              />
            </mask>
            <mask
              v-for="(segment, segmentIndex) in arcSegments"
              :id="`angleMask-${segmentIndex}`"
              :key="`angleMask-${segment.start}-${segmentIndex}`"
            >
              <path
                :d="
                  createArcPath(
                    segment,
                    segmentIndex === 0 ||
                      segmentIndex === arcSegments.length - 1
                  )
                "
                fill="white"
                stroke="white"
                :stroke-width="weight"
              />
            </mask>
            <mask id="filledArc">
              <path
                :d="
                  createArcPath(
                    { start: min, end: needleValue, color: arcColor },
                    true
                  )
                "
                fill="white"
                stroke="white"
                :stroke-width="weight"
              />
            </mask>
          </defs>

          <foreignObject :width="radius * 2" :height="radius">
            <div class="border-radius-round" :style="conicalStyle" />
          </foreignObject>

          <svg
            v-for="(segment, segmentIndex) in arcSegments"
            :key="`angleSegment-${segment.start}-${segmentIndex}-a`"
            mask="url(#strokeMask)"
          >
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              :mask="`url(#angleMask-${segmentIndex})`"
              :fill="segment.color"
              :opacity="
                segmentIndex === 0 || segmentIndex === arcSegments.length - 1
                  ? '30%'
                  : '100%'
              "
            />
          </svg>
          <svg
            v-for="(segment, segmentIndex) in arcSegments"
            :key="`angleSegment-${segment.start}-${segmentIndex}-b`"
            mask="url(#strokeMask)"
          >
            <rect
              v-if="value !== null && value >= 0"
              x="0"
              y="0"
              width="100%"
              height="100%"
              mask="url(#filledArc)"
              :fill="arcColor"
              opacity="100%"
            />
          </svg>
        </svg>
        <div
          class="marker-label"
          :style="markerLabelStyles({ percent: min, color: null })"
        >
          {{ min }}%
        </div>
        <template v-for="(marker, markerIndex) in markers">
          <div
            :key="`marker-${marker.percent}-${markerIndex}`"
            class="marker z-20"
            :style="markerStyles(marker)"
          />
          <div
            :key="`label-${marker.percent}-${markerIndex}`"
            class="marker-label"
            :style="markerLabelStyles(marker)"
          >
            {{ marker.percent }}%
          </div>
        </template>
        <div
          class="marker-label"
          :style="markerLabelStyles({ percent: max, color: null })"
        >
          {{ max }}%
        </div>
        <span
          class="score d-block text-center font-medium z-4"
          :style="scoreStyles"
        >
          {{ value === null ? '--' : value }}<span v-if="value !== null" class="font-20">%</span>
        </span>
        <span v-if="mainLabel" class="mainLabel" :style="mainLabelStyles">
          {{ mainLabel }}
        </span>
      </div>
    </div>
    <span v-if="secondaryLabel" :style="secondaryLabelStyles">
      {{ secondaryLabel }}
    </span>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { hexToRgb } from '../utils/color'

const BASE_SIZE = 250
@Component
export default class CUGauge extends Vue {
  @Prop({ type: Number, required: false, default: 24 })
  private value!: number
  @Prop({ type: Number, required: false, default: 250 })
  private width!: number
  @Prop({ type: Number, required: false, default: 12 })
  private weight!: number
  @Prop({ type: String, required: false, default: '' })
  private mainLabel!: string
  @Prop({ type: String, required: false, default: '' })
  private secondaryLabel!: string
  @Prop({ type: Array, required: false, default: () => [] })
  private thresholds!: { percent: number; color: string }[]
  @Prop({ type: Number, required: false, default: 0 })
  private min!: number
  @Prop({ type: Number, required: false, default: 100 })
  private max!: number

  get scale(): number {
    return this.width / BASE_SIZE
  }

  get markers(): { percent: number; color: string }[] {
    const markers = []
    const thresholdCount = this.thresholds.length

    for (let i = 0; i < thresholdCount; i++) {
      if (i === thresholdCount - 2) {
        continue
      }

      const threshold = this.thresholds[i]
      const color = threshold.color

      const isLastItem = i === thresholdCount - 1
      const percent = isLastItem
        ? threshold.percent
        : this.thresholds[i + 1].percent

      markers.push({ percent, color })
    }

    return markers
  }

  get radius(): number {
    return this.width / 2
  }

  get arcSegments(): {
    start: number
    end: number
    color: string
    opacity?: number
  }[] {
    const segments = []
    let lastPercent = this.max

    for (const threshold of this.sortedThresholds) {
      segments.push({
        start: threshold.percent,
        end: lastPercent,
        color: threshold.color,
      })
      lastPercent = threshold.percent
    }

    return segments.map((segment, segmentIndex) => {
      const isFirstOrLast =
        segmentIndex === 0 || segmentIndex === segments.length - 1
      return {
        ...segment,
        opacity: isFirstOrLast ? '30%' : '100%',
        color: isFirstOrLast
          ? segment.color
          : this.$vuetify.theme.themes.light.midnightGray50,
      }
    })
  }

  get wrapperStyles(): Record<string, string | number> {
    return {
      width: `${this.width}px`,
      height: `${this.radius}px`,
      position: 'relative',
    }
  }

  get scoreStyles(): Record<string, string | number> {
    const fontSize = `${40 * this.scale}px`
    const height = `${40 * this.scale}px`
    const bottom = `${32 * this.scale}px`
    return {
      color: this.$vuetify.theme.themes.light.midnightGray700 as string,
      fontSize,
      height,
      width: `${this.width}px`,
      display: 'block',
      textAlign: 'center',
      position: 'absolute',
      bottom,
    }
  }

  get mainLabelStyles(): Record<string, string | number> {
    const fontSize = `${12 * this.scale}px`
    return {
      color: this.$vuetify.theme.themes.light.midnightGray900 as string,
      fontSize,
      width: `${this.width}px`,
      display: 'block',
      textAlign: 'center',
      position: 'absolute',
      bottom: '0',
    }
  }

  get secondaryLabelStyles(): Record<string, string | number> {
    const marginTop = `${4 * this.scale}px`
    const fontSize = `${12 * this.scale}px`
    return {
      color: this.$vuetify.theme.themes.light.midnightGray300 as string,
      fontSize,
      marginTop,
      width: `${this.width}px`,
      display: 'block',
      textAlign: 'center',
    }
  }

  get dotContainerStyles(): Record<string, string | number> {
    let { x, y, angleRad } = this.getXYAndAngleFromPercent(this.needleValue, -0.5)
    const inwardAdjustment = this.weight / 2
    x -= inwardAdjustment * Math.cos(angleRad)
    y -= inwardAdjustment * Math.sin(angleRad)
    const diameter = `${20 * this.scale}px`

    return {
      width: diameter,
      height: diameter,
      borderRadius: diameter,
      position: 'absolute',
      top: `${y}px`,
      left: `${x}px`,
      zIndex: '4',
      overflow: 'hidden',
      backgroundColor: this.arcColor,
      transform: `translate(-50%, -50%)`,
      filter: 'drop-shadow(-1px 0px 2px rgba(0, 0, 0, 0.15))',
    }
  }

  get dotStyles(): Record<string, string | number> {
    const diameter = `${14 * this.scale}px` // Size of the dot
    return {
      width: diameter,
      height: diameter,
      borderRadius: '50%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: '3',
      backgroundColor: this.$vuetify.theme.themes.light.white as string,
      transform: 'translate(-50%, -50%)',
    }
  }

  get needleStyles(): Record<string, string | number> {
    const angleDeg = this.getXYAndAngleFromPercent(this.needleValue)?.angleDeg - 90
    const height = `${this.radius}px`
    const width = `${this.markerWidth}px`
    const top = `${this.radius}px`
    const left = `${this.radius}px`
    const transform = `rotate(${angleDeg}deg)`
    const color = hexToRgb(this.arcColor)
    const gradient = `linear-gradient(to bottom, rgba(${color},0) 50%, rgba(${color},1) 100%)`

    return {
      height,
      width,
      top,
      left,
      transform,
      transformOrigin: '0% 0%',
      position: 'absolute',
      zIndex: '3',
      backgroundImage: gradient,
    }
  }

  get sortedThresholds(): { percent: number; color: string }[] {
    return [...this.thresholds].sort((a, b) => a.percent - b.percent).reverse()
  }

  get arcColor(): string {
    for (const threshold of this.sortedThresholds) {
      if (this.needleValue >= threshold.percent) {
        return threshold.color
      }
    }

    return 'primary'
  }

  get needleValue(): number {
    return Math.max(this.value, this.min)
  }

  get markerHeight(): number {
    return 24 * this.scale
  }

  get markerWidth(): number {
    return this.markerHeight / 7
  }

  get conicalStyle(): Record<string, string | number> {
    const percent = ((this.needleValue - this.min) / (this.max - this.min)) * 100

    const adjustmentPercent = Math.pow(percent / 100, 2) * 15

    const totalPercent = 50 * (percent / 100) - adjustmentPercent

    const hexOpacity = Math.round((totalPercent / 100) * 255)
      .toString(16)
      .padStart(2, '0')
      .toUpperCase()

    const gradient = `conic-gradient(from 270deg at 50% 50%, ${this.arcColor}00 0%, ${this.arcColor}${hexOpacity} ${percent}%, ${this.arcColor}00 50%)`

    const path = this.createGradientPath({
      start: this.min,
      end: this.needleValue,
      color: this.arcColor,
    })

    return {
      height: `${this.radius * 2}px`,
      width: `${this.radius * 2}px`,
      clipPath: `path('${path}')`,
      background: gradient,
      border: '1px solid #000',
    }
  }

  markerStyles(threshold: {
    percent: number
    color: string
  }): Record<string, string | number> {
    const { percent, color } = threshold

    let { x, y, angleRad, angleDeg } = this.getXYAndAngleFromPercent(percent)
    const inwardAdjustment = this.weight / 2
    x -= inwardAdjustment * Math.cos(angleRad)
    y -= inwardAdjustment * Math.sin(angleRad)

    return {
      position: 'absolute',
      width: `${this.markerWidth}px`,
      height: `${this.markerHeight}px`,
      backgroundColor: color,
      left: `${x}px`,
      top: `${y}px`,
      transform: `translate(-50%, -50%) rotate(${angleDeg + 90}deg)`,
      borderRadius: `${this.markerWidth / 2}px`,
      zIndex: 3,
    }
  }

  markerLabelStyles(threshold: {
    percent: number
    color: string
  }): Record<string, string | number> {
    const { percent } = threshold

    let { x, y, angleRad } = this.getXYAndAngleFromPercent(percent)

    const outwardAdjustment = this.markerHeight
    x += outwardAdjustment * Math.cos(angleRad)
    y += outwardAdjustment * Math.sin(angleRad)

    const labelRotation = 0

    const fontSize = `${12 * this.scale}px`

    return {
      position: 'absolute',
      fontSize,
      left: `${x}px`,
      top: `${y}px`,
      transform: `translate(-50%, -50%) rotate(${labelRotation}deg)`,
      zIndex: 4,
    }
  }

  getXYAndAngleFromPercent(
    percent: number,
    percentAdjustment: number | null = null
  ): {
    x: number
    y: number
    angleRad: number
    angleDeg: number
  } {
    let adjustedPercent = ((percent - this.min) / (this.max - this.min)) * 100

    if (percentAdjustment) {
      adjustedPercent = adjustedPercent + percentAdjustment
    }
    const angleDeg = adjustedPercent * 1.8 - 90 - 90
    const angleRad = angleDeg * (Math.PI / 180)

    const x = this.radius + this.radius * Math.cos(angleRad)
    const y = this.radius + this.radius * Math.sin(angleRad)

    return { x, y, angleRad, angleDeg }
  }

  polarToCartesian(
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number
  ): { x: number; y: number } {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    }
  }

  calculateSegmentAngles(segment: {
    start: number
    end: number
    color: string
  }): { startAngle: number; endAngle: number } {
    const angleRange = 180 // From -90 to 90 degrees
    const oldRange = this.max - this.min

    const startAngle = ((segment.start - this.min) / oldRange) * angleRange - 90
    const endAngle = ((segment.end - this.min) / oldRange) * angleRange - 90
    return { startAngle, endAngle }
  }

  createArcPath(
    segment: {
      start: number
      end: number
      color: string
    },
    extend = false
  ): string {
    if (extend) {
      if (segment?.start === this.min) {
        segment.start -= (this.max - this.min) * 0.1
      }
      if (segment?.end === this.max) {
        segment.end += (this.max - this.min) * 0.1
      }
    }
    const { startAngle, endAngle } = this.calculateSegmentAngles(segment)
    const radius = this.radius - this.weight / 2
    const centerX = this.radius
    const centerY = this.radius
    const start = this.polarToCartesian(centerX, centerY, radius, endAngle)
    const end = this.polarToCartesian(centerX, centerY, radius, startAngle)
    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'
    return [
      'M',
      start.x,
      start.y,
      'A',
      radius,
      radius,
      0,
      largeArcFlag,
      0,
      end.x,
      end.y,
    ].join(' ')
  }

  createGradientPath(segment: {
    start: number
    end: number
    color: string
  }): string {
    let path = this.createArcPath(segment)
    const centerX = this.radius
    const centerY = this.radius
    path += ` L ${centerX} ${centerY}`

    return path
  }
}
</script>
