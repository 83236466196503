import dayjs, { OpUnitType, QUnitType } from 'dayjs'

/**
 * Returns an array of dates within the given range.
 * @param start The start date, in the format 'YYYY-MM-DD'.
 * @param end The end date, in the format 'YYYY-MM-DD'.
 * @returns An array of strings, each representing a date in the format 'YYYY-MM-DD'.
 */
export const getAllDatesInRange = (start: string, end: string): string[] => {
  const days: string[] = []
  let currentDate = dayjs(start)
  while (currentDate.diff(dayjs(end)) <= 0) {
    days.push(currentDate.format('YYYY-MM-DD'))
    currentDate = currentDate.add(1, 'day')
  }
  return days
}

/**
 * Returns a string of X days ago from today in UTC.
 * @param days The number of days ago.
 * @returns A string, representing a timestamp date in 'YYYY-MM-DD HH:mm:ss.SSS' format.
 */
export const getDaysAgo = (days: number): string => {
  if (!days || typeof days !== 'number') {
    return null
  }

  return dayjs()
    .utc()
    .startOf('day')
    .subtract(days, 'days')
    .format('YYYY-MM-DD HH:mm:ss.SSS')
}

/**
 * Calculates the time difference between two datetime strings in seconds.
 *
 * @param datetime1 - The earlier datetime string.
 * @param datetime2 - The later datetime string.
 * @param unit - Day.js units (e.g., 'minute', 'day', 'quarter').
 *               See Day.js's index.d.ts for all available options.
 * @returns The time difference in the specified units.
 */
export const getTimeBetween = (
  datetime1: string,
  datetime2: string,
  unit: OpUnitType | QUnitType
): number => {
  if (!datetime1 || !datetime2) {
    return null
  }
  return dayjs(datetime2).diff(dayjs(datetime1), unit)
}
