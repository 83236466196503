<template>
  <div>
    <p v-if="subLabel" class="text-gray-mid-light line-height-1">
      {{ subLabel }}
    </p>
    <p
      class="margin-t-0 margin-b-1"
      :class="{ 'text-gray-mid-light': disabled }"
    >
      {{ label }}
    </p>
    <div
      class="h-8 w-full"
      :class="{
        'background-primary': complete,
        'background-gray-mid-light': !complete,
      }"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class CUTimelineStep extends Vue {
  @Prop({ type: String, required: true, default: '' }) readonly label!: string
  @Prop({ type: String, required: false, default: '' })
  readonly subLabel!: string
  @Prop({ type: Boolean, required: true, default: '' })
  readonly complete!: boolean
  @Prop({ type: Boolean, required: false })
  readonly disabled!: boolean
}
</script>
