import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { StatusCodes } from 'http-status-codes'
import { clearLocalStorage } from '../../utils/localStorage'
import { setRequestConfig } from '../authTokenRepository'

export class HttpService {
  private service = axios.create()

  constructor() {
    this.service.interceptors.request.use(
      this.handleRequest,
      this.handleRequestError
    )

    this.service.interceptors.response.use(
      this.handleSuccess,
      this.handleResponseError
    )
    this.service.defaults.headers.common['Application-Origin'] = 'cupops'
  }

  handleRequest = async (
    config: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> => {
    // Do something before request is sent
    // If request is different than any of the URLS in urlsExcludedForBearerHeader
    // then send Authorization header with token from localstorage
    return await setRequestConfig(config)
  }

  handleRequestError = (error: any): Promise<any> => Promise.reject(error)

  private handleSuccess(response: AxiosResponse): AxiosResponse {
    return response
  }

  handleResponseError = async (error: any): Promise<any> => {
    if (error.response && error.response.status === 401) {
      if (error?.response?.status === StatusCodes.UNAUTHORIZED) {
        // We are going to clear localstorage, thereby logging out users on 401 errors
        await Vue.prototype.$auth0.logout()
        clearLocalStorage()
      }
    }
    return Promise.reject(error)
  }

  post<T, P>(
    url: string,
    payload: P,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.service.post<P, AxiosResponse<T>>(url, payload, config)
  }

  patch<T, P>(
    url: string,
    payload: P,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.service.patch<P, AxiosResponse<T>>(url, payload, config)
  }

  put<T, P>(
    url: string,
    payload: P,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.service.put<P, AxiosResponse<T>>(url, payload, config)
  }

  get<T, P>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.service.get<P, AxiosResponse<T>>(url, config)
  }

  delete<T, P>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.service.delete<P, AxiosResponse<T>>(url, config)
  }
}
