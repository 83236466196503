import { Action, Module, VuexModule } from 'vuex-class-modules'
import { Location, Route } from 'vue-router/types/router'

import { SystemParameter } from '@/models/dto/SystemParameter'
// register module (could be in any file)
import store from '@/store/index'
import systemParameter from '@/services/systemParameter'

@Module({ generateMutationSetters: true })
class AppModule extends VuexModule {
  // state
  _appTitle = 'CharterUP for Operators'
  _lastRoute: Location | null = null
  _systemParameters: SystemParameter[] = []
  _isMotiveOauthEnabled = false
  _canSendLastMinuteBidRequestMessages = false
  _isOpportunityStatusEnabled = false
  _arePendingRatesEnabled = false
  _isOperatorGuidanceEnabled = false
  _isSauconEnabled = false
  _isGeotabEnabled = false
  _isCompanyEldDropdownEnabled = false
  _staticSiteUrlOperatorCancellationPenalties: string | null = null
  _isCreateVehicleBlocksOnSoldOutEnabled = false
  _isQuickAddAvailabilityEnabled = false

  /**
   * Returns the app title.
   *
   * @returns The app title as a string.
   */

  get appTitle(): string {
    return this._appTitle
  }
  /**
   * Returns the last route.
   *
   * @returns The last route as a `Location` object.
   */
  get lastRoute(): Location {
    return this._lastRoute
  }

  /**
   * Returns the system parameters.
   *
   * @returns The system parameters as an array of `SystemParameter` objects.
   */
  get systemParameters(): SystemParameter[] {
    return this._systemParameters
  }

  /**
   * Returns whether "Last Minute Bid Request" messages can be sent.
   *
   * @returns A boolean indicating whether "Last Minute Bid Request" messages can be sent.
   */
  get canSendLastMinuteBidRequestMessages(): boolean {
    return this._canSendLastMinuteBidRequestMessages
  }

  /**
   * Returns whether opportunity status is enabled.
   *
   * @returns A boolean indicating whether opportunity status is enabled.
   */
  get isOpportunityStatusEnabled(): boolean {
    return this._isOpportunityStatusEnabled
  }

  /**
   * Returns whether pending rates are enabled.
   *
   * @returns A boolean indicating whether pending rates are enabled.
   */
  get arePendingRatesEnabled(): boolean {
    return this._arePendingRatesEnabled
  }

  /**
   * Returns whether Motive OAuth is enabled.
   *
   * @returns A boolean indicating whether Motive OAuth is enabled.
   */
  get isMotiveOauthEnabled(): boolean {
    return this._isMotiveOauthEnabled
  }

  /**
   * Returns whether operator guidance is enabled.
   *
   * @returns A boolean indicating whether operator guidance is enabled.
   */
  get isOperatorGuidanceEnabled(): boolean {
    return this._isOperatorGuidanceEnabled
  }

  /**
   * Returns whether Saucon ELD integration is enabled.
   *
   * @returns A boolean indicating whether Saucon integration is enabled.
   */
  get isSauconEnabled(): boolean {
    return this._isSauconEnabled
  }

  /**
   * Returns whether Geotab ELD integration is enabled.
   *
   * @returns A boolean indicating whether Geotab integration is enabled.
   */
  get isGeotabEnabled(): boolean {
    return this._isGeotabEnabled
  }

  /**
   * Returns whether Company ELD dropdown is enabled.
   *
   * @returns A boolean indicating whether company ELD dropdown is enabled.
   */
  get isCompanyEldDropdownEnabled(): boolean {
    return this._isCompanyEldDropdownEnabled
  }

  /**
   * Returns the static site URL for the operator cancellation penalties page
   *
   * @returns A string with the cancellation Url or null if the parameter isn't set
   */
  get staticSiteUrlOperatorCancellationPenalties(): string | null {
    return this._staticSiteUrlOperatorCancellationPenalties
  }

  /** Returns whether creating vehicle blocks on sold out action is enabled.
   *
   * @returns A boolean indicating whether creating vehicle blocks on sold out action is enabled.
   */
  get isCreateVehicleBlocksOnSoldOutEnabled(): boolean {
    return this._isCreateVehicleBlocksOnSoldOutEnabled
  }

  /** Returns whether quick add availability is enabled.
   *
   * @returns A boolean indicating whether quick add availability is enabled.
   */
  get isQuickAddAvailabilityEnabled(): boolean {
    return this._isQuickAddAvailabilityEnabled
  }

  /**
   * Save the last route.
   *
   * @param route The route to save.
   */
  @Action
  saveLastRoute(route: Route): void {
    this._lastRoute = {
      name: route?.name || undefined,
      path: route?.path,
      hash: route?.hash,
      query: route?.query,
      params: route?.params,
    }
  }

  /**
   * Asynchronously fetch system parameters.
   */
  @Action
  async fetchSystemParameters(): Promise<void> {
    const response = await systemParameter.tableView({
      sorts: null,
      filters: null,
      pageSize: -1,
      page: 1,
    })
    this._systemParameters = response?.data?.resultList || []
    this.calculateSystemParameters()
  }

  /**
   * Calculates the value of system parameters.
   */
  @Action
  calculateSystemParameters(): void {
    this._isMotiveOauthEnabled = checkSystemParameter(
      this._systemParameters,
      'cupopsMotiveOauthEnabled',
      'true'
    )
    this._canSendLastMinuteBidRequestMessages = checkSystemParameter(
      this._systemParameters,
      'canSendLastMinuteBidRequestMessages',
      'true'
    )
    this._isOpportunityStatusEnabled = checkSystemParameter(
      this._systemParameters,
      'enableOpportunityStatus',
      'true'
    )
    this._arePendingRatesEnabled = checkSystemParameter(
      this._systemParameters,
      'enablePendingRates',
      'true'
    )
    this._isOperatorGuidanceEnabled = checkSystemParameter(
      this._systemParameters,
      'isOperatorGuidanceEnabled',
      'true'
    )
    this._isSauconEnabled = checkSystemParameter(
      this._systemParameters,
      'isSauconEnabled',
      'true'
    )
    this._isGeotabEnabled = checkSystemParameter(
      this._systemParameters,
      'isGeotabEnabled',
      'true'
    )
    this._isCompanyEldDropdownEnabled = checkSystemParameter(
      this._systemParameters,
      'isCompanyEldDropdownEnabled',
      'true'
    )
    this._staticSiteUrlOperatorCancellationPenalties = getSystemParameterValue(
      this._systemParameters,
      'staticSiteUrlOperatorCancellationPenalties'
    )
    this._isCreateVehicleBlocksOnSoldOutEnabled = checkSystemParameter(
      this._systemParameters,
      'shouldCreateVehicleBlocksOnSoldOut',
      'true'
    )
    this._isQuickAddAvailabilityEnabled = checkSystemParameter(
      this._systemParameters,
      'isCupopsQuickAddAvailabilityEnabled',
      'true'
    )
  }
}

// PRIVATE HELPERS
const checkSystemParameter = (
  systemParameters: SystemParameter[],
  systemParameterName: string,
  value: string
): boolean | null => {
  const foundParameter = findSystemParameter(
    systemParameters,
    systemParameterName
  )
  return foundParameter?.value === value
}

const findSystemParameter = (
  systemParameters: SystemParameter[],
  systemParameterName: string
): SystemParameter | null => {
  return systemParameters.find(
    (systemParameter) => systemParameter.name === systemParameterName
  )
}

const getSystemParameterValue = (
  systemParameters: SystemParameter[],
  systemParameterName: string
): string | null => {
  return (
    findSystemParameter(systemParameters, systemParameterName)?.value ?? null
  )
}

export default new AppModule({ store, name: 'app' })
