<template>
<v-dialog
    :value="value"
    :fullscreen="fullscreen || $vuetify.breakpoint.xs"
    :width="width"
    :max-width="maxWidth"
    :persistent="persistent"
    v-bind="$attrs"
    @input="$emit('input', $event)"
    @keydown="keyListener"
>
    <template v-if="!!$scopedSlots.activator" #activator="{ on, attrs }">
    <slot name="activator" :on="on" :attrs="attrs" />
    </template>
    <div
    class="d-flex flex-column h-full overflow-x-hidden padding-x-6 padding-t-4 border-radius-regular background-white"
    :class="{
        'padding-b-6': !!$slots.actions,
        'padding-b-8': !$slots.actions,
    }"
    >
    <div
        class="padding-a-0 margin-b-6 d-flex align-center font-24 font-bold background-white text-gray font-bold border-b-0 border-transparent"
    >
        <slot name="title" />
        <template v-if="showCloseIcon">
        <v-spacer />
        <CUIcon
            id="cu-modal-close-button"
            class="cursor-pointer"
            :class="{
            'margin-r-2': closeIconRightPadding,
            }"
            view-box="0 0 24 24"
            @click="close"
        >
            close
        </CUIcon>
        </template>
    </div>
    <div
        class="d-flex h-full justify-center w-inherit"
        :class="{ 'padding-a-0': !!$slots.title }"
    >
        <slot name="text" />
    </div>
    <div v-if="!!$slots.actions" class="d-flex h-full padding-a-0 margin-t-6">
        <slot name="actions" />
    </div>
    </div>
</v-dialog>
</template>

<script lang="ts">
import { KeyCode } from '@/utils/enum'
import { Model, Prop, Vue, Component } from 'vue-property-decorator'

@Component
export default class CUModalV2 extends Vue {
@Model('input') readonly value!: boolean | null
@Prop({ type: String, required: false, default: '500px' }) width!: string
@Prop({ type: String, required: false, default: '500px' }) maxWidth!: string
@Prop({ type: Boolean, default: true }) closeable!: boolean
@Prop({ type: Boolean }) fullscreen!: boolean
@Prop({ type: Boolean }) persistent!: boolean
@Prop({ type: Boolean, default: false }) closeIconRightPadding!: boolean

get showCloseIcon(): boolean {
    return this.closeable
}

keyListener(event: any): void {
    if (event.keyCode === KeyCode.Escape && this.closeable) {
    this.$emit('input', false)
    }
}

close(): void {
    this.$emit('input', false)
    this.$emit('close')
}
}
</script>
  