import alert from './modules/alert'
import app from './modules/app'
import auth from './modules/auth'
import bidDetail from './modules/bidDetail'
import featureToggle from './modules/featureToggle'
import impersonation from './modules/impersonation'
import sidebar from './modules/sidebar'
import split from './modules/split'
import support from './modules/support'
import userDetail from './modules/userDetail'
import vehicleAvailability from './modules/vehicleAvailability'
import vehicleDetail from './modules/vehicleDetail'

const modules = {
  alert,
  app,
  auth,
  bidDetail,
  featureToggle,
  impersonation,
  sidebar,
  split,
  support,
  userDetail,
  vehicleAvailability,
  vehicleDetail,
}

export default modules
