<template>
  <CUSkeletonLoaderTextField v-if="skeletonLoader" :label="$attrs.label" />
  <div v-else>
    <label v-if="$attrs.label" class="font-14 ">
      {{ $attrs.label }}
      <span v-if="required" class="required-asterisk">*</span>
    </label>
    <v-select
      v-bind="$attrs"
      :label="null"
      outlined
      :item-text="itemText"
      :item-value="itemValue"
      :items="items"
      :multiple="multiple"
      solo
      flat
      :value="value"
      append-icon="mdi-chevron-down"
      v-on="listeners"
    >
      <template v-if="displaySelectAll" #prepend-item>
        <div
          class="v-list-item primary--text v-list-item--link theme--light v-list-item--highlighted"
          :class="{
            'v-list-item--active': isAllToggled,
          }"
          @click="toggleAllFilters"
        >
          <v-simple-checkbox
            hide-details
            :style="{ 'flex-basis': '13.5%' }"
            color="primary"
            class="margin-y-0 padding-a-0 w-full"
            :value="isAllToggled"
            :ripple="false"
            @click="toggleAllFilters"
          />
          <span class="text-black font-16">All</span>
        </div>
      </template>
      <template
        v-if="aggregateSelectionIndex && !$slots['custom-selection-label']"
        #selection="{ item, index }"
      >
        <span v-if="index < aggregateSelectionIndex" class="margin-l-1">
          {{ aggregateSelectionText(item, index) }}
        </span>
        <span
          v-if="index === aggregateSelectionIndex && value.length"
          class="text-gray-mid-light margin-l-1"
        >
          (+{{ value.length - aggregateSelectionIndex }} others)
        </span>
      </template>
      <template
        v-else-if="!!$slots['custom-selection-label']"
        #selection="{ index }"
      >
        <slot v-if="index === 0" name="custom-selection-label" />
      </template>
      <template v-for="(index, name) in $scopedSlots" #[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
    </v-select>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import CUSkeletonLoaderTextField from '@/components/CUSkeletonLoaderTextField.vue'

@Component({ components: { CUSkeletonLoaderTextField } })
export default class CUSelect extends Vue {
  @Prop({ required: false, type: Boolean }) displaySelectAll: boolean
  @Prop({ type: Boolean, required: false, default: false })
  skeletonLoader: boolean
  // For selects with the `multiple` prop.
  // When selecting more than this index, display just (+# others)
  @Prop({ required: false, type: Number }) aggregateSelectionIndex: number
  @Prop({ required: false, default: 'text', type: String }) itemText!: string
  @Prop({ required: false, default: 'value', type: String }) itemValue!: string
  @Prop({ required: false }) items!: any
  @Prop({ required: false, default: () => [] }) value!: string | unknown[]
  @Prop({ type: Boolean, required: false, default: false }) multiple!: boolean
  @Prop({ required: false, type: Boolean, default: false}) required: boolean

  isAllToggled = true

  @Watch('value', { immediate: true })
  onValueChange(newVal: unknown[]): void {
    if (!this.multiple) {
      return
    }

    if (newVal?.length === this.items?.length) {
      this.isAllToggled = true
    } else {
      this.isAllToggled = false
    }
  }

  get slots(): any {
    return this.$slots
  }

  get listeners(): Record<string, unknown> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this
    return Object.assign({}, this.$listeners, {
      input(event: InputEvent) {
        vm.$emit('input', event)
      },
      change(event: InputEvent) {
        vm.$emit('change', event)
      },
    })
  }

  aggregateSelectionText(item: any, index: number): string {
    const appendText =
      index !== this.aggregateSelectionIndex - 1 &&
      this.value.length - 1 > index
        ? ', '
        : ''
    return `${item[this.itemText]}${appendText}`
  }

  toggleAllFilters(): void {
    if (!this.isAllToggled) {
      if (this.itemValue) {
        this.$emit(
          'input',
          this.items.map((item) => item[this.itemValue])
        )
      } else {
        this.$emit('input', this.items)
      }
    } else {
      this.$emit('input', [])
    }
  }
}
</script>

<style lang="scss" scoped>
.required-asterisk {
  color: $error;
}
</style>
