import { VuexModule, Module, Action } from 'vuex-class-modules'

// register module (could be in any file)
import store from '@/store/index'

@Module({ generateMutationSetters: true })
class SidebarModule extends VuexModule {
  _isOpen = false

  /**
   * Returns whether the modal is open.
   * @returns A boolean indicating whether the modal is open.
   */
  get isOpen(): boolean {
    return this._isOpen
  }

  /**
   * Opens the modal.
   */
  @Action
  open() {
    this._isOpen = true
  }

  /**
   * Closes the modal.
   */
  @Action
  close() {
    this._isOpen = false
  }
}
export default new SidebarModule({ store, name: 'sidebar' })
