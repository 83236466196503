import { bffProxyUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import {
  ApiResult,
  DeleteVehiclePhotoPayload,
  VehicleDetailEntity,
  VehiclePhotoDTO,
  TableViewVehicle,
  VehicleValidationResult,
  VehicleDetailResponse,
} from '@/models/dto'
import vehicleDetail from '@/store/modules/vehicleDetail'
import { GenericApiResult } from '@/models/dto/GenericApiResult'
import { TrackingSource } from '@/models/dto/Tracking'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Gets the vehicle table view.
   * @param params - the parameters for the table view.
   * @returns the table view result of vehicles.
   */
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<TableViewVehicle>>> {
    const { sorts = null, filters = null, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)
    const host = bffProxyUrl
    const url = `${host}/tables/vehicles?${query}`
    return httpService.get(url)
  },
  /**
   * Gets a vehicle by ID.
   * @param vehicleId - The ID of the vehicle to fetch.
   * @returns the vehicle detail.
   */
  byId(vehicleId: number): Promise<AxiosResponse<VehicleDetailResponse>> {
    const host = bffProxyUrl
    const url = `${host}/vehicles/${vehicleId}`
    return httpService.get(url)
  },
  /**
   * Creates a vehicle.
   * @param vehicle - The vehicle to create.
   * @returns the ID of the created vehicle.
   */
  create(
    vehicle: VehicleDetailEntity
  ): Promise<AxiosResponse<GenericApiResult<number>>> {
    const host = bffProxyUrl
    const url = `${host}/vehicles`
    return httpService.post(url, vehicle)
  },
  /**
   * Updates a vehicle.
   * @param vehicle - The vehicle to update.
   */
  update(vehicle: VehicleDetailEntity): Promise<AxiosResponse<ApiResult>> {
    const host = bffProxyUrl
    const url = `${host}/vehicles/${vehicle.vehicleId}`
    return httpService.put(url, vehicle)
  },
  /**
   * Deletes a vehicle.
   * @param vehicleId - The ID of the vehicle to delete.
   * @returns the result of the delete operation.
   */
  delete(vehicleId: number): Promise<AxiosResponse<ApiResult>> {
    const host = bffProxyUrl
    const url = `${host}/vehicles/${vehicleId}`
    return httpService.delete(url)
  },
  /**
   * Uploads vehicle photos.
   * @param vehicleId - The ID of the vehicle to upload photos for.
   * @param form - The form data containing the photos to upload.
   * @returns the result of the upload operation.
   */
  uploadPhotos(
    vehicleId: number,
    form: FormData
  ): Promise<AxiosResponse<boolean>> {
    const url = `${bffProxyUrl}/vehicles/${vehicleId}/vehiclePhotos`
    const config = {
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const uploadPercentage = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        )
        vehicleDetail.setUploadPercentage(uploadPercentage)
      },
    }
    return httpService.post<boolean, FormData>(url, form, config)
  },
  /**
   * Sets and existing vehicle photo as the default photo.
   * @param vehicleId - the ID of the vehicle to set the default photo for.
   * @param vehiclePhoto - the vehicle photo to set as the default.
   */
  makeVehiclePhotoDefault(
    vehicleId: number,
    vehiclePhoto: VehiclePhotoDTO
  ): Promise<AxiosResponse> {
    const url = `${bffProxyUrl}/vehicles/${vehicleId}/updateVehiclePhoto`
    return httpService.patch(url, vehiclePhoto)
  },
  /**
   * Deletes vehicle photos.
   * @param vehicleId - the ID of the vehicle to delete photos for.
   * @param payload - the payload containing the photos to delete.
   * @returns the result of the delete operation.
   */
  deleteVehiclePhotos(
    vehicleId: number,
    payload: DeleteVehiclePhotoPayload
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `${bffProxyUrl}/vehicles/${vehicleId}/removeVehiclePhotos`
    return httpService.delete(url, {
      data: payload,
    })
  },

  /**
   * Gets device and gps data for vehicles.
   * @param vehicles - the list of vehicle objects to search for.
   * @returns the tracking source data for the input vehicles.
   */
  getVehicleTrackingData(
    vehicles: VehicleDetailEntity[]
  ): Promise<AxiosResponse<GenericApiResult<TrackingSource>>> {
    const url = `${bffProxyUrl}/v2/tracking/deviceInfo`
    return httpService.post(url, vehicles)
  },

  /**
   * Checks if a VIN is valid and returns make, model, and model year information encoded in the VIN
   * @param vin - the vin to validate and decode
   * @param vehicleId - the id of the vehicle the vin would get set to if the vehicle already exists in the db
   * @returns the make, model, and model year information if the VIN is valid, an error message if it is not
   */
  validateVIN(
    vin: string,
    vehicleId: number
  ): Promise<AxiosResponse<VehicleValidationResult>> {
    let url = null
    if (!vehicleId) {
      url = `${bffProxyUrl}/vehicles/validateVIN/${vin}`
    } else {
      url = `${bffProxyUrl}/vehicles/validateVIN/${vin}?vehicleId=${vehicleId}`
    }
    return httpService.get(url)
  },

  /**
   * Create a vehicle with a vin review.
   * @param vehicle - the vehicle that will be created with a vin review.
   * @returns the id of the vehicle that was created
   */
  createWithReview(
    vehicle: VehicleDetailEntity
  ): Promise<AxiosResponse<GenericApiResult<number>>> {
    const url = `${bffProxyUrl}/vehicles/createWithReview`
    return httpService.post(url, vehicle)
  },

  /**
   * Updates a vehicle and creates a vin review.
   * @param vehicle - The vehicle to update.
   */
  updateWithReview(
    vehicle: VehicleDetailEntity
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `${bffProxyUrl}/vehicles/updateWithReview/${
      vehicle.vehicleId
    }`
    return httpService.put(url, vehicle)
  },
}
