import { bffProxyUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import {
  AvailabilityGetRequest,
  AvailabilityGetResponse,
  AvailabilityBlockGetRequest,
  AvailabilityBlockGetResponse,
  VehicleAvailabilityBlock,
  AvailabilityDataGetResponse,
  AvailabilityDataGetRequest,
  CompanyAvailabilityBlock,
} from '@/models/dto/Availability'
import { ApiResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Retrieves the availability reservation data for the given dates.
   *
   * @param dates - An object containing the start and end dates for the availability data to retrieve.
   * @returns A promise that resolves to an AxiosResponse with the availability data.
   */
  getReservationAvailabilityData(
    dates: AvailabilityGetRequest
  ): Promise<AxiosResponse<AvailabilityGetResponse>> {
    return httpService.post(
      `${bffProxyUrl}/tripAssignments/getAvailabilityView`,
      dates
    )
  },
  /**
   * Retrieves all availability data for the given dates including reservations, vehicle availability blocks, company availability blocks, and availability labels.
   *
   * @param body - An object containing the start and end dates for the availability data to retrieve.
   * @returns A promise that resolves to an AxiosResponse with the availability data.
   */
  getCUPOPSAvailabilityData(
    body: AvailabilityDataGetRequest
  ): Promise<AxiosResponse<AvailabilityDataGetResponse>> {
    return httpService.post(
      `${bffProxyUrl}/availabilityBlocks/getCUPOPSAvailability`,
      body
    )
  },
  /**
   * Retrieves the block data for the given request parameters.
   *
   * @param body - An object containing the request parameters.
   * @returns A promise that resolves to an AxiosResponse with the block data.
   */
  getBlockData(
    body: AvailabilityBlockGetRequest
  ): Promise<AxiosResponse<AvailabilityBlockGetResponse>> {
    return httpService.post(
      `${bffProxyUrl}/availabilityBlocks/getVehicleBlocks`,
      body
    )
  },
  /**
   * Retrieves the company blocks data for the given request parameters.
   *
   * @param body - An object containing the request parameters.
   * @returns A promise that resolves to an AxiosResponse with the company blocks data.
   */
  getCompanyBlocksData(
    body: AvailabilityBlockGetRequest
  ): Promise<AxiosResponse<AvailabilityBlockGetResponse>> {
    return httpService.post(
      `${bffProxyUrl}/availabilityBlocks/getCompanyBlocks`,
      body
    )
  },
  /**
   * Creates a new vehicle availability block with the given information.
   *
   * @param body - An object containing the information for the availability block to create.
   * @returns A promise that resolves to an AxiosResponse with the result of the create request.
   */
  createVehicleBlock(
    body: VehicleAvailabilityBlock
  ): Promise<AxiosResponse<ApiResult>> {
    return httpService.post(
      `${bffProxyUrl}/availabilityBlocks/createVehicleBlock`,
      body
    )
  },
  /**
   * Updates an existing vehicle availability block with the given information.
   *
   * @param body - An object containing the updated information for the availability block.
   * @returns A promise that resolves to an AxiosResponse with the result of the update request.
   */
  editVehicleBlock(
    body: VehicleAvailabilityBlock
  ): Promise<AxiosResponse<ApiResult>> {
    return httpService.patch(
      `${bffProxyUrl}/availabilityBlocks/updateVehicleBlock`,
      body
    )
  },
  /**
   * Deletes a vehicle availability block with the given information.
   *
   * @param body - An object containing the information for the availability block to delete.
   * @returns A promise that resolves to an AxiosResponse with the result of the delete request.
   */
  deleteVehicleBlock(
    body: VehicleAvailabilityBlock
  ): Promise<AxiosResponse<ApiResult>> {
    return httpService.delete(
      `${bffProxyUrl}/availabilityBlocks/deleteVehicleBlock`,
      { data: body }
    )
  },
  /**
   * Creates a new company availability block with the given information.
   *
   * @param body - An object containing the information for the availability block to create.
   * @returns A promise that resolves to an AxiosResponse with the result of the create request.
   */
  createCompanyBlock(
    body: CompanyAvailabilityBlock
  ): Promise<AxiosResponse<ApiResult>> {
    return httpService.post(
      `${bffProxyUrl}/availabilityBlocks/createCompanyBlock`,
      body
    )
  },
  /**
   * Deletes a company availability block with the given information.
   *
   * @param body - An object containing the information for the availability block to delete.
   * @returns A promise that resolves to an AxiosResponse with the result of the delete request.
   */
  deleteCompanyBlock(
    body: CompanyAvailabilityBlock
  ): Promise<AxiosResponse<ApiResult>> {
    return httpService.delete(
      `${bffProxyUrl}/availabilityBlocks/deleteCompanyBlock`,
      { data: body }
    )
  },
}
