import { bffProxyUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import {
  Company,
  CompanyEldTypesResult,
  CompanyResult,
} from '@/models/dto/Company'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import {
  ApiResult,
  ReservationReview,
  VINReviewsResult,
  VINVerificationStatusResponse,
} from '@/models/dto'
import { EldType } from '@/models/EldCredential'
import { TrackingSourcesResult } from '@/models/dto/Tracking'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Retrieves a table view of company data with the given parameters.
   *
   * @param params - An object containing the request parameters, including sorting, filtering, pagination, and page size.
   * @returns the company data for the table view.
   */
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<Company>>> {
    const { filters = null, sorts = null, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)
    const host = bffProxyUrl

    return httpService.get(`${host}/tables/companies?${query}`)
  },
  /**
   * Retrieves the information for a specific company.
   *
   * @param companyId - The ID of the company to retrieve.
   * @returns A promise that resolves to an AxiosResponse with the company information.
   */
  byId(companyId: number): Promise<AxiosResponse<CompanyResult>> {
    const host = bffProxyUrl
    const url = `${host}/companies/${companyId}`
    return httpService.get(url)
  },
  /**
   * Updates the information for a specific company.
   *
   * @param companyId - The ID of the company to update.
   * @param company - An object containing the updated information for the company.
   * @returns A promise that resolves to an AxiosResponse with a success message.
   */
  update(companyId: number, company: Company): Promise<AxiosResponse<string>> {
    const host = bffProxyUrl
    const url = `${host}/companies/${companyId}`
    return httpService.patch(url, company)
  },
  /**
   * @param company - Partial company object containing the details to be updated.
   * @returns A promise resolving to the AxiosResponse from the onboarding request.
   */
  onboard(company: Partial<Company>): Promise<AxiosResponse<string>> {
    const host = bffProxyUrl
    const url = `${host}/companies/onboardCUPOPS`
    return httpService.patch(url, company)
  },
  /**
   * @param companyId - The ID of the company to upload the branding photo for.
   * @param type - The type of branding photo to upload (e.g. "dark" or "light").
   * @param photo - The photo to upload, as a FormData object.
   * @returns A promise resolving to the AxiosResponse from the upload request.
   */
  uploadBranding(
    companyId: number,
    type: string,
    photo: FormData
  ): Promise<AxiosResponse<boolean>> {
    const host = bffProxyUrl
    const url = `${host}/companies/${companyId}/companyBrandings/${type}`
    return httpService.put(url, photo)
  },
  /**
   * @param payload - FormData object containing the photo to upload and other relevant information.
   * @param companyId - The ID of the company to upload the fleet photo for.
   * @returns A promise resolving to the AxiosResponse from the upload request.
   */
  uploadFleetPhoto(
    payload: FormData,
    companyId: number
  ): Promise<AxiosResponse<ApiResult>> {
    const host = bffProxyUrl
    const url = `${host}/vehicles/${companyId}/vehiclePhotosCharterUp`
    return httpService.post(url, payload)
  },
  reviews(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<ReservationReview>>> {
    const { sorts = null, filters = null, pageSize = 10, page = 1 } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const host = bffProxyUrl
    const url = `${host}/reservationReviews?${query}`
    return httpService.get(url)
  },
  /**
   * Gets the ELD types for a specific company.
   *
   * @returns A promise that resolves to an AxiosResponse with a success message.
   */
  getEldTypes(): Promise<AxiosResponse<CompanyEldTypesResult>> {
    const host = bffProxyUrl
    const url = `${host}/companies/eldTypes`
    return httpService.get(url)
  },
  /**
   * Updates the ELD types for a specific company.
   *
   * @param eldTypes - The array of ELD types to set for the company.
   * @returns A promise that resolves to an AxiosResponse with a success message.
   */
  updateEldTypes(eldTypes: Array<EldType>): Promise<AxiosResponse<void>> {
    const host = bffProxyUrl
    const url = `${host}/companies/updateEldTypes`
    return httpService.post(url, eldTypes)
  },
  /**
   * Gets the tracking sources for a specific company.
   *
   * @param companyId - The ID of the company to get tracking sources for.
   * @returns A promise that resolves to an AxiosResponse with a success message.
   */
  getTrackingSources(
    companyId: number
  ): Promise<AxiosResponse<TrackingSourcesResult>> {
    const host = bffProxyUrl
    const url = `${host}/gpsData/view/company/${companyId}`
    return httpService.get(url)
  },
  /**
   * Gets the active VIN reviews for a specific company.
   *
   * @param companyId - The ID of the company to get VIN reviews for.
   * @returns A promise that resolves to an AxiosResponse with a success message.
   */
  getVINReviews(): Promise<AxiosResponse<VINReviewsResult>> {
    const host = bffProxyUrl
    const url = `${host}/vehicles/vinReviews/getAllForCompany`
    return httpService.get(url)
  },
  /**
   * Gets the VIN verification statuses for all the vehicles for a specific company.
   *
   * @param companyId - The ID of the company to get VIN verification statuses for.
   * @returns A promise that resolves to an AxiosResponse with a success message.
   */
  getVINVerificationStatuses(
    companyId: number
  ): Promise<AxiosResponse<VINVerificationStatusResponse>> {
    const host = bffProxyUrl
    const url = `${host}/vehicles/${companyId}/getVINVerificationStatuses`
    return httpService.get(url)
  },
}
