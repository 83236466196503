import { Action, Module, VuexModule } from 'vuex-class-modules'
import {
  BidPayload,
  BidPayloadVehicle,
  TableViewTrip,
  Trip,
} from '@/models/dto'

import { BidStatusId } from '@/utils/enum'
import { TableViewBid } from '@/models/dto/TableViewBid'
import auth from './auth'
import bid from '@/services/bid'
import deepClone from '@/utils/deepClone'
import { getExistingBidsByTripId } from '@/utils/bid'
import store from '@/store/index'
import tripService from '@/services/trip'

@Module({ generateMutationSetters: true })
class BidDetailModule extends VuexModule {
  // state
  _trip: Trip | null = null
  _trips: TableViewTrip[] | null = null
  _tripDetails: Trip[] = []
  _bids: { [tripId: number]: TableViewBid } | null = null
  _isEnteringBid = false
  _isPriceUpdated = false
  _areBidsComplete = true
  _bidAmounts: { [tripId: number]: number | null } = {}
  _bidAmount: number | null = null
  _areAllSoldOut = false
  _submitting = false
  _isSubmitted = false
  _showSoldOutConfirmationMessage = false
  _loading = true
  _notFound = false

  /**
   * Returns the current trip.
   *
   * @returns The current trip.
   */
  get trip() {
    return this._trip
  }

  /**
   * Returns the list of trips.
   *
   * @returns The list of trips.
   */
  get trips() {
    return this._trips
  }

  /**
   * Returns the details of the current trip.
   *
   * @returns The details of the current trip.
   */
  get tripDetails() {
    return this._tripDetails
  }

  /**
   * Returns the list of bids for all trips.
   *
   * @returns The list of bids for all trips.
   */
  get bids() {
    return this._bids
  }

  /**
   * Returns whether the price of the current bid has been updated.
   * @returns A boolean indicating whether the price of the current bid has been updated.
   */
  get bidPriceUpdated() {
    return this._isPriceUpdated
  }

  /**
   * Returns whether the current bid can be submitted.
   * @returns A boolean indicating whether the current bid can be submitted.
   */
  get isSubmitEnabled() {
    return this._isPriceUpdated && this._areBidsComplete && !this._isSubmitted
  }

  /**
   * Returns the current bid.
   * @returns The current bid.
   */
  get bid() {
    if (this._bids && this._trip) {
      return this._bids[this._trip.tripId]
    }
    return null
  }

  /**
   * Returns the amount of the current bid.
   * @returns The amount of the current bid.
   */
  get bidAmount() {
    return this._bidAmount
  }

  /**
   * Returns whether the current bid is sold out.
   * @returns A boolean indicating whether the current bid is sold out.
   */
  get isSoldOut() {
    if (this._bids && this._trip && !this._bidAmount) {
      return this._bids?.[this._trip.tripId]?.soldOut || false
    }
    return false
  }

  /**
   * Returns whether the user is currently entering a bid.
   * @returns A boolean indicating whether the user is currently entering a bid.
   */
  get isEnteringBid() {
    return this._isEnteringBid
  }

  /**
   * Returns the list of bid amounts for the current trip.
   * @returns The list of bid amounts for the current trip.
   */
  get bidAmounts() {
    return this._bidAmounts
  }

  /**
   * Returns whether all trips are sold out.
   * @returns A boolean indicating whether all trips are sold out.
   */
  get areAllSoldOut(): boolean {
    return this._areAllSoldOut
  }

  /**
   * Returns whether the current bid is being submitted.
   * @returns A boolean indicating whether the current bid is being submitted.
   */
  get submitting(): boolean {
    return this._submitting
  }

  /**
   * Returns whether to show a message indicating that the user cannot change the price of the current bid.
   * @returns A boolean indicating whether to show a message indicating that the user cannot change the price of the current bid.
   */
  get showChangePriceMessage(): boolean {
    return this._isEnteringBid
  }

  /**
   * Returns whether to show loaders.
   * @returns A boolean indicating whether to show loaders.
   */
  get showLoaders(): boolean {
    return this._loading
  }

  /**
   * Returns whether the current trip is being loaded.
   * @returns A boolean indicating whether the current trip is being loaded.
   */
  get loading(): boolean {
    return this._loading
  }

  /**
   * Returns whether the current trip was not found.
   * @returns A boolean indicating whether the current trip was not found.
   */
  get notFound(): boolean {
    return this._notFound
  }

  /**
   * Returns whether to show a confirmation message for a sold out trip.
   * @returns A boolean indicating whether to show a confirmation message for a sold out trip.
   */
  get showSoldOutConfirmationMessage(): boolean {
    return this._showSoldOutConfirmationMessage
  }

  /**
   * Returns whether the current trip is a Spab trip.
   * @returns A boolean indicating whether the current trip is a Spab trip.
   */
  get isSpabTrip(): boolean {
    return !!this._trip?.spab
  }

  /**
   * Resets the state of the store.
   */
  @Action
  reset(): void {
    this._trip = null
    this._trips = null
    this._tripDetails = []
    this._bids = null
    this._bidAmounts = {}
    this._bidAmount = null
    this._isEnteringBid = false
    this._loading = true
    this._notFound = false
  }

  /**
   * Fetches a list of trips for a given quote ID.
   *
   * @param quoteId - The ID of the quote to fetch trips for.
   */
  @Action
  async fetchTripsListByQuoteId(quoteId: number): Promise<void> {
    const params = {
      page: 1,
      pageSize: -1,
      sorts: undefined,
      filters: undefined,
    }
    const tripsResponse = await tripService.tableView(
      params,
      quoteId.toString()
    )
    this._trips = tripsResponse.data.resultList.map(
      (trip) => processTrip(trip) as TableViewTrip
    )
  }

  /**
   * Fetches the detailed information for all trips in the current quote.
   */
  @Action
  async fetchAllTripDetails(): Promise<void> {
    let tripIds: number[] = []
    if (this._trips) {
      tripIds = this._trips.map((trip) => trip.tripId)
    }
    if (tripIds.length) {
      const tripDetails: Trip[] = await Promise.all(
        tripIds.map((tripId) => fetchTripDetail(tripId))
      )
      this._tripDetails = tripDetails
    }
  }

  /**
   * Fetches the existing bids for the trips in the current trips.
   */
  @Action
  async fetchExistingBids(): Promise<void> {
    let tripIds: number[] = []
    if (this._trips) {
      tripIds = this._trips.map((trip) => trip.tripId)
    }
    if (tripIds.length) {
      const bids: { [tripId: number]: TableViewBid } = {}
      const bidAmounts: { [tripId: number]: number | null } = {}
      const bidsList = await Promise.all(
        tripIds.map((tripId) => fetchExistingBidByTripId(tripId))
      )
      for (const bid of bidsList) {
        if (!bid) {
          continue
        }
        const tripId = bid.tripId
        bids[tripId] = bid
        bidAmounts[tripId] = bid?.bidAmount || null
        if (!bidAmounts[tripId]) {
          this._areBidsComplete = false
        }
      }
      this._bidAmounts = bidAmounts
      this._bids = bids
      let allSoldOut = true
      for (const bid of Object.values(bids)) {
        if (!bid?.soldOut) {
          allSoldOut = false
          break
        }
      }
      this._areAllSoldOut = allSoldOut
    }
  }

  /**
   * Fetches trip data for a given quote ID.
   * @param quoteId - The ID of the quote to retrieve trip data for.
   */
  @Action
  async fetchTrips(quoteId: number): Promise<void> {
    this._loading = true
    try {
      await this.fetchTripsListByQuoteId(quoteId)
      const tripsCount = this._trips.length
      if (tripsCount) {
        await this.fetchAllTripDetails()
        await this.fetchExistingBids()
        if (tripsCount === 1) {
          this.selectTrip(this._trips[0].tripId)
        }
      } else {
        this._notFound = true
        return
      }
    } catch (e) {
      this._notFound = true
      return
    }
    this._loading = false
  }

  /**
   * Selects a trip and sets the associated bid amount, if any.
   * @param tripId - The ID of the trip to select.
   */
  @Action
  selectTrip(tripId: number): void {
    this._trip =
      this._tripDetails.find((trip) => trip.tripId === tripId) || null
    this._bidAmount = this._bidAmounts[tripId] || null
  }

  /**
   * Deselects the selected trip and clears the associated bid amount.
   */
  @Action
  deselectTrip(): void {
    this._trip = null
    this._bidAmount = null
  }

  /**
   * Sets the bid amount for a given trip.
   * @param tripBid - The trip ID and bid amount to set.
   */
  @Action
  setTripBidAmount(tripBid: { tripId: number; bidAmount: number }): void {
    this._bidAmounts[tripBid.tripId] = tripBid.bidAmount
    if (tripBid.tripId === this._trip?.tripId) {
      this._bidAmount = tripBid.bidAmount
    }
    this._isSubmitted = false
    this._isPriceUpdated = true
    this._areBidsComplete =
      Object.values(this._bidAmounts).filter((bid) => !!bid).length ===
      this._trips.length
  }

  /**
   * Submits a bid for a single trip.
   * @param tripBid An object containing the trip ID and the bid amount.
   * @returns A promise that resolves once the bid has been submitted.
   */
  @Action
  async submitSingleTripBid(tripBid: {
    tripId: number
    bidAmount: number
  }): Promise<void> {
    this._submitting = true
    this.setTripBidAmount(tripBid)
    const payload = await this.buildSingleTripPayload()
    if (payload) {
      try {
        if (this.bid?.bidId) {
          await bid.update(this.bid.bidId, payload)
        } else {
          await bid.create(payload)
        }
        await this.fetchExistingBids()
        this.setIsEnteringBid(false)
      } catch (err) {
        console.error(err)
      }
    }
    this._submitting = false
  }

  /**
   * Submit multiple trip bids.
   * @param tripId - The ID of the trip to be submitted.
   * @param bidAmount - The bid amount for the trip.
   * @returns A promise that resolves when the bids have been submitted.
   */
  @Action
  async submitMultiTripBids(): Promise<void> {
    this._submitting = true
    const payloads: BidPayload[] = await Promise.all(
      this.tripDetails.map((trip) => this.buildMultiTripPayload(trip))
    )
    const bidPromises = payloads.map((payload) => {
      if (!payload) {
        return
      }
      if (payload.existingBid) {
        return bid.update(payload.existingBid, payload)
      } else {
        return bid.create(payload)
      }
    })
    await Promise.all(bidPromises)
    this.fetchExistingBids()
    this._submitting = false
    this._isSubmitted = true
  }

  /**
   * Sets the flag indicating whether the user is entering a bid.
   * @param value - The value to set the flag to.
   */
  @Action
  setIsEnteringBid(value: boolean): void {
    this._isEnteringBid = value
  }

  /**
   * Builds a payload for a multi-trip bid.
   * @param trip - The trip to build the payload for.
   * @returns A payload for the trip.
   */
  @Action
  buildMultiTripPayload(trip: Trip): BidPayload | null {
    let payload = null
    const bidAmount = this._bidAmounts[trip.tripId]
    const originalBid = this._bids?.[trip.tripId]
    if (bidAmount) {
      payload = buildPayload(trip, bidAmount, originalBid, true)
    }
    return payload
  }

  /**
   * Builds a payload for a single-trip bid.
   * @returns A payload for the trip.
   */
  @Action
  buildSingleTripPayload(): BidPayload | null {
    let payload = null
    if (this._trip) {
      const bid = this._bids?.[this._trip.tripId]
      const bidAmount = this._bidAmounts?.[this._trip.tripId]
      if (bidAmount) {
        payload = buildPayload(this._trip, bidAmount, bid)
      }
    }
    return payload
  }

  /**
   * Opens the confirmation modal for marking a trip as sold out.
   */
  @Action
  openMarkSoldOutConfirmation(): void {
    this._showSoldOutConfirmationMessage = true
  }

  /**
   * Closes the confirmation modal for marking a trip as sold out.
   */
  @Action
  closeMarkSoldOutConfirmation(): void {
    this._showSoldOutConfirmationMessage = false
  }

  /**
   * Marks a trip as sold out.
   */
  @Action
  async markSoldOut(): Promise<void> {
    if (this._trip) {
      await this.markSingleTripSoldOut(null)
    } else {
      await this.markAllTripsSoldOut()
    }
    this.closeMarkSoldOutConfirmation()
  }

  /**
   * Marks all trips as sold out.
   */
  @Action
  async markAllTripsSoldOut(): Promise<void> {
    this._submitting = true
    if (this._tripDetails) {
      await Promise.all(
        this._tripDetails.map((trip) => this.markSingleTripSoldOut(trip))
      )
      await this.fetchExistingBids()
    }
    this._submitting = false
  }

  /**
   * Marks a single trip as sold out.
   * @param trip - The trip to mark as sold out.
   */
  @Action
  async markSingleTripSoldOut(trip: Trip | null): Promise<void> {
    this._submitting = true
    const isMultiTripBid = !!trip
    trip = trip || this._trip
    if (trip) {
      try {
        const payload = await buildSoldOutPayload(trip)
        if (!payload) {
          return
        }
        const bidId = this.bids?.[trip.tripId]?.bidId
        if (bidId) {
          await bid.update(bidId, payload)
        } else {
          await bid.create(payload)
        }
        if (this._trip?.tripId) {
          this._bidAmounts[this._trip?.tripId] = null
        }
        if (!isMultiTripBid) {
          await this.fetchExistingBids()
          this._bidAmount = null
          this.setIsEnteringBid(false)
          this._submitting = false
        }
      } catch (err) {
        console.error(err)
      }
    }
  }
}

// PRIVATE HELPERS
/**
 * Processes a trip by sorting its stops in ascending order of their order index.
 * @param trip - The trip to process
 * @returns The processed trip
 */
const processTrip = (trip: Trip | TableViewTrip): Trip | TableViewTrip => {
  if (trip.stops) {
    trip.stops = trip.stops.sort((a, b) => a.orderIndex - b.orderIndex)
  }
  return trip
}

/**
 * @param trip - A trip object for which a sold out bid payload needs to be built.
 * @returns A BidPayload object representing a sold out bid.
 */
const buildSoldOutPayload = (trip: Trip): BidPayload => {
  const userId = auth.userId
  if (!userId) {
    return
  }
  return {
    tripId: trip.tripId,
    companyId: auth.user!.companyId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    userId,
    bidStatusId: BidStatusId.Pending,
    providerNotes: null,
    active: true,
    soldOut: true,
    bidAmount: 0,
    bidVehicles: [],
    driverCount: 0,
  }
}

const buildPayload = (
  trip: Trip,
  bidAmount: number,
  originalBid: TableViewBid | null = null,
  isMultiTripBid = false
): BidPayload => {
  const active = true
  let bidPassengerCount
  if (originalBid) {
    bidPassengerCount = originalBid.soldOut
      ? null
      : originalBid?.bidPassengerCount
  } else {
    bidPassengerCount = trip.passengerCount
  }

  const bidStatusId = BidStatusId.Pending
  const bidVehicles = buildBidVehicles(trip)
  const companyId = auth.user.companyId
  const driverCount = originalBid?.driverCount || trip.requiredDrivers
  const providerNotes = originalBid?.providerNotes || ''
  const tripId = trip.tripId
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const userId = auth.userId!
  const existingBid = originalBid?.bidId || 0

  const payload: BidPayload = {
    active,
    bidAmount,
    bidPassengerCount,
    bidStatusId,
    bidVehicles,
    companyId,
    driverCount,
    providerNotes,
    tripId,
    userId,
  }
  if (isMultiTripBid) {
    payload.existingBid = existingBid
    const originalBidPayload = deepClone(payload)
    originalBidPayload.existingBid = existingBid
    originalBidPayload.bidAmount = originalBid?.bidAmount || 0
    payload.originalBid = originalBidPayload
  }
  return payload
}

const fetchTripDetail = async (tripId: number): Promise<Trip> => {
  const tripResponse = await tripService.byId(tripId)
  return processTrip(tripResponse.data) as Trip
}

const fetchExistingBidByTripId = async (
  tripId: number
): Promise<TableViewBid> => {
  const bidsResult = await getExistingBidsByTripId(tripId)
  const existingBids = bidsResult.data.resultList.filter(
    (bid: TableViewBid) => bid.active
  )
  return existingBids?.[0] || null
}

const buildBidVehicles = (trip: Trip): BidPayloadVehicle[] => {
  return trip.vehicles.map((vehicle) => {
    const vehicleType = {
      active: true,
      companyId: null,
      ...vehicle.vehicleType,
    }
    return {
      quantity: vehicle.quantity,
      vehicleId: vehicle.vehicleId,
      vehicleType,
    }
  })
}
export default new BidDetailModule({ store, name: 'bidDetail' })
