import { Action, Module, VuexModule } from 'vuex-class-modules'
import auth from '@/store/modules/auth'
import company from '@/services/company'
import credentials from '@/services/credentials'
import store from '..'
import { TrackingSource } from '@/models/dto/Tracking'
import { VINReview } from '@/models/dto/Vehicle'

@Module({ generateMutationSetters: true })
class CompanyModule extends VuexModule {
  _hasEldCredentials = false
  _eldTypes: string[] = []
  _trackingSources: TrackingSource[] = []
  _vinReviews: VINReview[] = []

  get hasEldCredentials(): boolean {
    return this._hasEldCredentials
  }

  get eldTypes(): string[] {
    return this._eldTypes
  }

  get trackingSources(): TrackingSource[] {
    return this._trackingSources
  }

  get vinReviews(): VINReview[] {
    return this._vinReviews
  }

  @Action
  async getEldCredentials(): Promise<void> {
    const credentialsResponse = await credentials.getAllByCompanyIdV2(
      auth.user.companyId
    )
    const result = credentialsResponse?.data?.data
    this._hasEldCredentials = result?.length > 0
    const eldTypes = []
    for (const credential of result) {
      eldTypes.push(credential.eldType)
    }
    this._eldTypes = eldTypes
  }

  @Action
  async getTrackingSources(): Promise<void> {
    let trackingSourcesResponse
    try {
      trackingSourcesResponse = await company.getTrackingSources(
        auth.company.companyId
      )
    } catch (e) {
      console.error(e)
      return
    }
    this._trackingSources = trackingSourcesResponse?.data?.data
  }

  @Action
  async getVINReviews(): Promise<void> {
    const vinReviewsResponse = await company.getVINReviews()
    const vinReviews = vinReviewsResponse?.data?.vinReviews
    this._vinReviews = vinReviews.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
  }
}

export default new CompanyModule({ store, name: 'company' })
