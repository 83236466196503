<template>
  <div>
    <label v-if="$attrs.label">{{ $attrs.label }}</label>
    <v-textarea
      v-bind="$attrs"
      :label="undefined"
      solo
      flat
      outlined
      v-on="listeners"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class CUTextArea extends Vue {
  get listeners(): Record<string, unknown> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this
    return Object.assign({}, this.$listeners, {
      input(event: InputEvent) {
        vm.$emit('input', event)
      },
    })
  }
}
</script>
