import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules'
import {
  ApiResult,
  DeleteVehiclePhotoPayload,
  VehicleAmenityDTO,
  VehicleDetailEntity,
  VehiclePhotoDTO,
} from '@/models/dto'

import { RawLocation } from 'vue-router'
import app from './app'
import auth from './auth'
import deepClone from '@/utils/deepClone'
import { removeItem } from '@/utils/array'
import router from '@/router'
// register module (could be in any file)
import store from '@/store/index'
import vehicle from '@/services/vehicle'
import company from '@/store/modules/company'
import { GenericApiResult } from '@/models/dto/GenericApiResult'
import { AxiosResponse } from 'axios'

const NEW_VEHICLE: VehicleDetailEntity = {
  active: false,
  companyId: null,
  deviceId: null,
  garageId: null,
  garageName: '',
  licensePlate: '',
  passengerCapacity: null,
  showOnOperatorPage: false,
  vehicleAmenityDTOs: [],
  vehicleId: null,
  vehicleMake: '',
  vehicleModel: '',
  vehicleName: '',
  vehiclePhotoDTOs: [],
  vehicleTypeId: null,
  vehicleTypeName: '',
  vehicleYear: null,
  vinNumber: '',
}

@Module({ generateMutationSetters: true })
class VehicleDetailModule extends VuexModule {
  _routeName: string | null = null
  _isModeAdd = false
  _isModeEdit = false
  _isModeView = false
  _uploadPercentage = 0
  _vehicleId: number | null = null
  _vehicle: VehicleDetailEntity = NEW_VEHICLE
  _vehicles: VehicleDetailEntity[] = []
  _currentVehicleIndex: number = null
  _notFound = false
  _saving = false
  _loading = true
  _isVINValid: boolean = null

  /**
   * Get the name of the route.
   * @returns the name of the route.
   */
  get routeName(): string | null {
    return this._routeName
  }
  /**
   * Check if the mode of the form is "add".
   * @returns `true` if the mode of the form is "add", `false` otherwise.*/
  get isModeAdd(): boolean {
    return this._isModeAdd
  }
  /**
   * Check if the mode of the form is "edit".
   * @returns `true` if the mode of the form is "edit", `false` otherwise.
   */
  get isModeEdit(): boolean {
    return this._isModeEdit
  }
  /**
   * Check if the mode of the form is "view".
   * @returns `true` if the mode of the form is "view", `false` otherwise.
   */
  get isModeView(): boolean {
    return this._isModeView
  }
  /**
   * Get the upload percentage for the vehicle photos.
   * @returns the upload percentage for the vehicle photos.
   */
  get uploadPercentage(): number {
    return this._uploadPercentage
  }
  /**
   * Get the details of the vehicle.
   * @returns the vehicle detail
   */
  get vehicle(): VehicleDetailEntity {
    return this._vehicle
  }
  /**
   * Get the ID of the vehicle.
   * @returns the vehicle ID.
   */
  get vehicleId(): number | null {
    return this._vehicleId
  }
  /**
   * Gets the list of vehicles.
   * @returns the list of vehicles
   */
  get vehicles(): VehicleDetailEntity[] {
    return this._vehicles
  }
  /**
   * Get the current vehicle index.
   * @returns the current vehicle index.
   */
  get currentVehicleIndex(): number | null {
    return this._currentVehicleIndex
  }
  /**
   * Get the list of photos for the vehicle.
   * @returns the list of photos for the vehicle.
   */
  get vehiclePhotos(): VehiclePhotoDTO[] {
    return this._vehicle?.vehiclePhotoDTOs || []
  }
  /**
   * Check if the vehicle is not found.
   * @returns `true` if the vehicle is not found
   */
  get notFound(): boolean {
    return this._notFound
  }
  /**
   * Check if the form is currently saving.
   * @returns whether the form is currently saving.
   */
  get saving(): boolean {
    return this._saving
  }
  /**
   * Check if the form is currently loading.
   * @returns whether the form is currently loading
   */
  get showLoaders(): boolean {
    return this._loading
  }

  /**
   * Get the last route before navigating to the vehicle edit page.
   * If the last route is the vehicle edit page or not found, the default route is "vehicles".
   * @returns the last route before navigating to the vehicle detail page.
   */
  get getLastRoute(): RawLocation {
    const lastRoute = app.lastRoute
    if (!app.lastRoute?.name || app.lastRoute?.name === 'vehicles.edit') {
      return { name: 'vehicles' }
    }
    return lastRoute
  }

  get isVINValid(): boolean {
    return this._isVINValid
  }

  /**
   * Set the current vehicle index.
   * @param index - The current vehicle index.
   */
  @Mutation
  setCurrentVehicleIndex(index: number) {
    this._currentVehicleIndex = index
  }
  /**
   * Set the current vehicle device ID.
   * @param deviceId - The device ID.
   */
  @Mutation
  setDeviceId(deviceId: string) {
    this._vehicle.deviceId = deviceId
  }
  /**
   * Set the current vehicle ELD type.
   * @param eldType - The ELD type.
   */
  @Mutation
  setEldType(eldType: string) {
    this._vehicle.eldType = eldType
  }
  /**
   * Set the vehicle amenities.
   * @param vehicleAmenities - The list of vehicle amenities.
   */
  @Mutation
  setVehicleAmenities(vehicleAmenities: VehicleAmenityDTO[]) {
    if (this._currentVehicleIndex !== null) {
      this._vehicles[this._currentVehicleIndex].vehicleAmenityDTOs =
        vehicleAmenities
    } else {
      this._vehicle.vehicleAmenityDTOs = vehicleAmenities
    }
  }
  /**
   * Set the vehicle photos.
   * @param {VehiclePhotoDTO[]} vehiclePhotos - The list of vehicle photos.
   */
  @Mutation
  setVehiclePhotos(vehiclePhotos: VehiclePhotoDTO[]) {
    this._vehicle.vehiclePhotoDTOs = vehiclePhotos
  }
  /**
   * Set the label of a vehicle photo.
   * @param {VehiclePhotoDTO} photo - The vehicle photo to set the label for.
   */
  @Mutation
  setPhotoDTOLabel(photo: VehiclePhotoDTO) {
    let foundPhoto = this._vehicle.vehiclePhotoDTOs.find(
      (p) => p.imagePath === photo.imagePath
    )
    if (foundPhoto) {
      foundPhoto = photo
    }
  }
  /**
   * Set a vehicle photo as the primary photo.
   * @param {VehiclePhotoDTO} photo - The vehicle photo to set as primary.
   */
  @Mutation
  setPhotoDTOPrimaryImage(photo: VehiclePhotoDTO) {
    for (const photo of this._vehicle.vehiclePhotoDTOs) {
      photo.primaryImage = false
    }

    const foundPhoto = this._vehicle.vehiclePhotoDTOs.find(
      (p) => p.imagePath === photo.imagePath
    )
    if (foundPhoto) {
      foundPhoto.primaryImage = true
    }
  }

  @Mutation
  setIsVINValid(isVINValid: boolean) {
    this._isVINValid = isVINValid
  }

  /**
   * Initialize the vehicle edit form.
   */
  @Action
  initialize(): void {
    this._vehicle = NEW_VEHICLE
    this._uploadPercentage = 0
    this._vehicleId = null
    this._vehicles = []
    this._currentVehicleIndex = null
    this._isVINValid = null
  }
  /**
   * Initialize the vehicle edit form with a vehicle entity.
   * @param vehicle - The vehicle entity.
   */
  @Action
  initializeVehicleSync(vehicles: VehicleDetailEntity[]): void {
    this._vehicle = NEW_VEHICLE
    this._uploadPercentage = 0
    this._vehicleId = null
    this._isModeAdd = true
    this._isModeEdit = false
    this._isModeView = false
    this._loading = false
    this._isVINValid = null
    this._vehicles = vehicles.map((vehicle) => {
      return { ...deepClone(NEW_VEHICLE), ...vehicle }
    })
  }
  /**
   * Load the vehicle data for the form.
   */
  @Action
  async load(): Promise<void> {
    this._loading = true
    this._vehicles = []
    this._currentVehicleIndex = null
    this.setUploadPercentage(0)
    await Promise.all([company.getEldCredentials(), company.getVINReviews()])
    if (company.hasEldCredentials) {
      await company.getTrackingSources()
    }
    if (this._isModeAdd) {
      this.initialize()
      const vehicle = deepClone(this._vehicle)
      if (vehicle) {
        vehicle.active = true
        vehicle.companyId = auth.user?.companyId || null
        this.setVehicle(vehicle)
      }
    } else {
      await this.fetchVehicle()
    }
    this._loading = false
  }
  /**
   * Fetch the vehicle data from the server.
   */
  @Action
  async fetchVehicle(): Promise<void> {
    try {
      if (this._vehicleId) {
        const response = await vehicle.byId(Number(this._vehicleId))
        const vehicleData = await processVehicle(response.data?.vehicle)
        const validateVINResult = await vehicle.validateVIN(
          vehicleData.vinNumber,
          this.vehicleId
        )
        if (validateVINResult.data.successful) {
          this.setIsVINValid(true)
        } else {
          this.setIsVINValid(false)
        }
        this.setVehicle(vehicleData)
      } else {
        this._notFound = true
      }
    } catch (e) {
      this._notFound = true
      console.error(e)
    }
  }
  /**
   * Fetches the vehicle's ELD device information.
   */
  @Action
  async fetchDeviceInformation(): Promise<void> {
    if (this._vehicle.vinNumber) {
      const response = await vehicle.getVehicleTrackingData([this._vehicle])
      const deviceInfo = response.data.data
      if (deviceInfo) {
        this.setDeviceId(deviceInfo[0]?.trackingDeviceId)
        this.setEldType(deviceInfo[0]?.eldType)
      }
    } else {
      this.setDeviceId('')
      this.setEldType('')
    }
  }
  /**
   * Sets the route name.
   * @param routeName - The name of the route.
   */
  @Action
  setRouteName(routeName: string): void {
    this._routeName = routeName
    this._isModeAdd = routeName === 'vehicles.add'
    if (this._isModeAdd) {
      this._loading = false
    }
    this._isModeEdit = routeName === 'vehicles.edit'
    this._isModeView = routeName === 'vehicles.view'
  }
  /**
   * Sets the upload percentage for a vehicle.
   * @param percent - The percentage of the upload that has completed.
   */
  @Action
  setUploadPercentage(percent: number) {
    this._uploadPercentage = percent
  }
  /**
   * Sets the details of a vehicle.
   * @param vehicle - The details of the vehicle.
   */
  @Action
  setVehicle(vehicle: VehicleDetailEntity) {
    if (this._currentVehicleIndex !== null) {
      this._vehicles[this._currentVehicleIndex] = vehicle
    } else {
      this._vehicle = vehicle
    }
  }
  /**
   * Sets the ID of a vehicle.
   * @param vehicleId - The ID of the vehicle.
   */
  @Action
  setVehicleId(vehicleId: number | string | null) {
    if (typeof vehicleId === 'string') {
      vehicleId = parseInt(vehicleId)
    }
    this._vehicleId = vehicleId
  }
  /**
   * Sets whether the vehicle is currently being saved.
   * @param saving - Whether the vehicle is currently being saved.
   */
  @Action
  setSaving(saving: boolean) {
    this._saving = saving
  }
  /**
   * Sets the amenities of a vehicle.
   * @param vehicleAmenities - The amenities of the vehicle.
   */
  @Action
  setAmenities(vehicleAmenities: VehicleAmenityDTO[]) {
    this.setVehicleAmenities(vehicleAmenities)
  }
  /**
   * Sets the photos of a vehicle.
   * @param vehiclePhotos - The photos of the vehicle.
   */
  @Action
  setPhotos(vehiclePhotos: VehiclePhotoDTO[]) {
    this.setVehiclePhotos(vehiclePhotos)
  }
  /**
   * Navigates the user to the previous route.
   */
  @Action
  goBack(): void {
    if (!app.lastRoute?.name || app.lastRoute?.name === 'vehicles.edit') {
      router.push({ name: 'vehicles' })
    } else {
      router.push(app.lastRoute)
    }
  }
  /**
   * Begins the process of editing a vehicle.
   */
  @Action
  beginEdit(): void {
    if (this._vehicleId) {
      router.push({
        name: 'vehicles.edit',
        params: { id: this._vehicleId.toString() },
      })
    }
  }
  /**
   * Sets the label for a vehicle photo.
   * @param obj - An object containing the photo and the label to set.
   */
  @Action
  setPhotoLabel(obj: { photo: VehiclePhotoDTO; label: string }): void {
    const photo = obj.photo
    photo.label = obj.label
    this.setPhotoDTOLabel(photo)
  }
  /**
   * Sets a photo as the primary photo for a vehicle.
   * @param photo - The photo to set as the primary photo.
   */
  @Action
  setPhotoAsPrimaryImage(photo: VehiclePhotoDTO): void {
    this.setPhotoDTOPrimaryImage(photo)
  }

  /**
   * Uploads photos for a vehicle.
   */
  @Action
  async uploadPhotos(): Promise<void> {
    if (this._vehicleId) {
      let currentVehicle
      if (this._currentVehicleIndex) {
        currentVehicle = this._vehicles[this._currentVehicleIndex]
      } else {
        currentVehicle = this._vehicle
      }
      const newVehiclePhotos = buildNewPhotosPayload(
        currentVehicle.vehiclePhotoDTOs
      )
      if (newVehiclePhotos) {
        await Promise.all(
          newVehiclePhotos.map((form) =>
            vehicle.uploadPhotos(this._vehicleId, form)
          )
        )
      }
    }
  }

  /**
   * Updates the default photo for a vehicle.
   */
  @Action
  async updateDefaultPhoto(): Promise<void> {
    if (!this._vehicleId) {
      return
    }
    const defaultPhoto = this._vehicle.vehiclePhotoDTOs.find(
      (p) => p.primaryImage
    )

    // If the defaultPhoto is a photo which still needs to be uploaded
    if (!defaultPhoto || defaultPhoto?.file || !defaultPhoto.active) {
      return
    }
    await vehicle.makeVehiclePhotoDefault(this._vehicleId, defaultPhoto)
  }

  /**
   * Adds photos to a vehicle.
   * @param photos - The photos to add to the vehicle.
   */
  @Action
  addPhotos(photos: VehiclePhotoDTO[]): void {
    let newPhotosList
    if (this._currentVehicleIndex !== null) {
      newPhotosList = this._vehicles[this._currentVehicleIndex].vehiclePhotoDTOs
    } else {
      newPhotosList = this._vehicle.vehiclePhotoDTOs
    }
    for (const photo of photos) {
      newPhotosList.push(photo)
    }
  }

  /**
   * Removes a photo from a vehicle.
   * @param photo - The photo to remove from the vehicle.
   */
  @Action
  removePhoto(photo: VehiclePhotoDTO): void {
    photo.active = false
    if (photo.file) {
      let vehiclePhotoList = deepClone(this.vehiclePhotos)
      vehiclePhotoList = removeItem(vehiclePhotoList, photo)
      this.setVehiclePhotos(vehiclePhotoList)
    }
  }

  /**
   * Updates the details of a vehicle using the provided method.
   */
  @Action
  async updateVehicleUsingMethod(
    updateVehicleMethod: (
      vehicle: VehicleDetailEntity
    ) => Promise<AxiosResponse<ApiResult>>
  ): Promise<ApiResult> {
    if (this._vehicleId) {
      try {
        const deletedPhotos = buildDeletePhotosPayload(this._vehicle)
        if (deletedPhotos.vehiclePhotoIds.length) {
          await vehicle.deleteVehiclePhotos(this._vehicleId, deletedPhotos)
        }
        const response = await updateVehicleMethod(this._vehicle)
        if (response.data.successful) {
          await this.updateDefaultPhoto()
          await this.uploadPhotos()
          router.push({
            name: 'vehicles.view',
            params: { id: this._vehicleId.toString() },
          })
        }
        return response.data
      } catch (e) {
        console.error(e)
      }
    }
  }

  /**
   * Updates the details of a vehicle.
   */
  @Action
  async updateVehicle(): Promise<ApiResult> {
    return this.updateVehicleUsingMethod(vehicle.update)
  }

  /**
   * Updates the details of a vehicle while withholding vin changes using a vin review
   */
  @Action
  async updateVehicleWithReview(): Promise<ApiResult> {
    return this.updateVehicleUsingMethod(vehicle.updateWithReview)
  }

  /**
   * Adds a vehicle.
   * @param addVehicleMethod - The method to call to add a vehicle.
   * @param skipRedirect - Whether to skip redirecting to the new vehicle's page.
   */
  @Action
  async addVehicleUsingMethod(
    addVehicleMethod: (
      vehicle: VehicleDetailEntity
    ) => Promise<AxiosResponse<GenericApiResult<number>>>,
    skipRedirect = false
  ): Promise<GenericApiResult<number>> {
    try {
      let currentVehicle
      if (this._currentVehicleIndex !== null) {
        currentVehicle = this._vehicles[this._currentVehicleIndex]
      } else {
        currentVehicle = this._vehicle
      }
      // If there are vehiclePhotoDTOs and no primary image
      // is set, set the first photo to be the primary image.
      if (currentVehicle.vehiclePhotoDTOs?.length) {
        if (
          !currentVehicle.vehiclePhotoDTOs.filter((p) => p.primaryImage).length
        ) {
          currentVehicle.vehiclePhotoDTOs[0].primaryImage = true
        }
      }

      const vehicleResponse = await addVehicleMethod(currentVehicle)
      const vehicleId = vehicleResponse.data.data
      this.setVehicleId(vehicleId)
      await this.uploadPhotos()
      if (!skipRedirect && vehicleResponse.data.successful) {
        router.push({
          name: 'vehicles.view',
          params: { id: vehicleId.toString() },
        })
      }
      return vehicleResponse.data
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * Adds a vehicle.
   * @param skipRedirect - Whether to skip redirecting to the new vehicle's page.
   */
  @Action
  async addVehicle(skipRedirect = false): Promise<GenericApiResult<number>> {
    return this.addVehicleUsingMethod(vehicle.create, skipRedirect)
  }

  /**
   * Adds a vehicle with a review.
   * @param skipRedirect - Whether to skip redirecting to the new vehicle's page.
   */
  @Action
  async addVehicleWithReview(
    skipRedirect = false
  ): Promise<GenericApiResult<number>> {
    return this.addVehicleUsingMethod(vehicle.createWithReview, skipRedirect)
  }
}

//private helpers
const processVehicle = async (
  vehicleDetail: VehicleDetailEntity
): Promise<VehicleDetailEntity> => {
  if (vehicleDetail && typeof vehicleDetail.vehicleTypeId === 'string') {
    vehicleDetail.vehicleTypeId = parseInt(vehicleDetail.vehicleTypeId)
  }
  vehicleDetail.eldType = vehicleDetail.deviceEldTypeLabel
  vehicleDetail.vehicleAmenityDTOs = vehicleDetail.vehicleAmenityDTOs.sort(
    (a, b) => a.amenityId - b.amenityId
  )
  return vehicleDetail
}

const buildNewPhotosPayload = (
  vehiclePhotoDTOs: VehiclePhotoDTO[]
): FormData[] | null => {
  const forms = []
  for (const photo of vehiclePhotoDTOs) {
    if (photo?.file) {
      const form = new FormData()
      form.append('file', photo.file)
      form.append('label', photo.label)
      form.append('primaryImage', String(photo.primaryImage))
      forms.push(form)
    }
  }
  return forms.length ? forms : null
}

const buildDeletePhotosPayload = (
  vehicle: VehicleDetailEntity
): DeleteVehiclePhotoPayload => {
  const deletedPhotos = vehicle.vehiclePhotoDTOs
    .filter((photo) => !photo.active)
    .map((photo) => {
      return photo.vehiclePhotoId
    })
  return { vehiclePhotoIds: deletedPhotos }
}
export default new VehicleDetailModule({ store, name: 'vehicleDetail' })
