import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

const API_KEY = 'AIzaSyBWMUg6c-A-QMo76aMVO7CVXF_GpzQvSP0'

Vue.use(VueGoogleMaps, {
  load: {
    key: API_KEY,
    installComponents: true,
  },
})
