import {datadogLogs, LogsInitConfiguration} from '@datadog/browser-logs'
import {datadogRum, RumInitConfiguration} from '@datadog/browser-rum'
import {EnvironmentName, resolveEnvironment} from "@/utils/env";

function datadogEnvName(): string | null {
    if (process.env.VUE_APP_DD_ENV_OVERRIDE) {
        // This is useful when testing Datadog integration or setup during local development,
        // but shouldn't be used outside of that.
        return process.env.VUE_APP_DD_ENV_OVERRIDE
    }
    const environmentName: EnvironmentName = resolveEnvironment();
    if (environmentName === EnvironmentName.dev) {
        return 'dev'
    } else if (environmentName === EnvironmentName.staging) {
        return 'staging'
    } else if (environmentName === EnvironmentName.production) {
        return 'production'
    } else {
        return null
    }
}
/**
 * Update the Datadog current session with information about the active user.
 */
export function setUserContext(id: string | null, email: string | null, name: string | null) {
    datadogRum.setUser({
        id: id,
        email: email,
        name: name
    })
}
/**
 * Update the Datadog current session with information about the active user.
 */
export function clearUserContext() {
    datadogRum.stopSession()
    datadogRum.clearUser()
}
export function initDatadog(): void {
    const DATADOG_ENV = datadogEnvName()
    if (DATADOG_ENV !== null) {
        console.log("Initializing datadog for environment '" + DATADOG_ENV + "'")
        // we're running in one of our deployed environments, initialize datadog
        const DATADOG_APPLICATION_ID = '4dc84775-8487-4f9d-bb0a-35612ef5adba'
        const DATADOG_CLIENT_TOKEN = 'pub266fa6a6625211a30e070b9b3d9f97d2'
        const DATADOG_SITE = 'us5.datadoghq.com'
        const DATADOG_SERVICE = 'charterup-operators-ui'
        const DATADOG_APP_VERSION = process.env.DD_VERSION || 'unknown'
        const DATADOG_CONFIG: RumInitConfiguration & LogsInitConfiguration = {
            applicationId: DATADOG_APPLICATION_ID,
            clientToken: DATADOG_CLIENT_TOKEN,
            site: DATADOG_SITE,
            service: DATADOG_SERVICE,
            env: DATADOG_ENV,
            version: DATADOG_APP_VERSION,
            // Browser RUM config
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'allow',
            // Browser APM tracing config
            traceSampleRate: 20,
            traceContextInjection: 'sampled',
            allowedTracingUrls: [
                {
                    match: /https:\/\/.*\.operators\.charterup\.com/,
                    propagatorTypes: ['datadog', 'tracecontext'],
                },
            ],
            // Logging configuration
            forwardErrorsToLogs: true,
            forwardConsoleLogs: ['warn', 'error'],
            forwardReports: 'all'
        }
        // Initialize Datadog log collection
        datadogLogs.init(DATADOG_CONFIG);
        // Set up browser session tracking and APM
        datadogRum.init(DATADOG_CONFIG)
    } else {
        console.log('Skipping datadog instrumentation')
    }
}