import { bffProxyUrl } from '@/utils/env'
import {
  UserAuthResult,
  UserAuthPayload,
  UserProfileResult,
  ApiResult, 
  SetPasswordPayload, 
  ValidatePasswordResponse,
  ValidatePasswordRequest,
} from '@/models/dto'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Performs a login request to the API with the provided credentials.
   *
   * @param payload - An object containing the login credentials.
   * @returns A promise that resolves to an AxiosResponse with the login result.
   */
  login(payload: UserAuthPayload): Promise<AxiosResponse<UserAuthResult>> {
    const url = `${bffProxyUrl}/auth/a3`
    return httpService.post<UserAuthResult, UserAuthPayload>(url, payload)
  },
  /**
   * Retrieves the user's profile information from the API.
   *
   * @returns A promise that resolves to an AxiosResponse with the user's profile information.
   */
  getUserProfile(): Promise<AxiosResponse<UserProfileResult>> {
    const url = `${bffProxyUrl}/userProfile`
    return httpService.get(url)
  },
  /**
   * Retrieves the user's profile information from the API using the JWT token.
   *
   * @returns A promise that resolves to an AxiosResponse with the user's profile information.
   */
  getUserProfileWithToken(): Promise<AxiosResponse<UserAuthResult>> {
    const url = `${bffProxyUrl}/auth/jwt`
    return httpService.post(url, {})
  },
  /**
   * Changes the password of the currently logged in user.
   *
   * @param payload - An object containing the new password and, optionally, the old password.
   * @returns A promise that resolves to an AxiosResponse with the result of the password change request.
   */
  setPassword(payload: SetPasswordPayload): Promise<AxiosResponse<ApiResult>> {
    const url = `${bffProxyUrl}/password/set`
    return httpService.post(url, payload)
  },
  /**
   * Changes the password of a user as an administrator.
   *
   * @param payload - An object containing the new password and the user's ID.
   * @returns A promise that resolves to an AxiosResponse with the result of the password change request.
   */
  adminSetPassword(
    payload: SetPasswordPayload
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `${bffProxyUrl}/password/set/admin`
    return httpService.post(url, payload)
  },
  /**
   * Validates password against endpoint in Auth service
   *
   * @param passwordCandidate - new password chosen by the user
   * @returns A promise with the result of validation
   */
  validatePassword(passwordCandidate: string): Promise<AxiosResponse<ValidatePasswordResponse>> {
    const url = `${bffProxyUrl}/password/validate`
    return httpService.post<ValidatePasswordResponse, ValidatePasswordRequest>(url, { newPassword: passwordCandidate })
  }
}
