import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules'
import {
  AvailabilityBlockReasonType,
  VehicleAvailabilityBlock,
} from '@/models/dto/Availability'
import { TableViewVehicle, Vehicle, VehicleType } from '@/models/dto'

import availability from '@/services/availability'
import dayjs from 'dayjs'
import deepClone from '@/utils/deepClone'
// register module (could be in any file)
import store from '@/store/index'
import type from '@/services/type'
import vehicle from '@/services/vehicle'
import { VehicleAvailabilityCreationSource } from '@/utils/enum'

@Module({ generateMutationSetters: true })
class VehicleAvailabilityModule extends VuexModule {
  _allVehicles: TableViewVehicle[] = []
  _filteredVehicles: TableViewVehicle[] = []
  _companyVehicleTypes: VehicleType[] = []
  _vehicleTypes: VehicleType[] = []
  _selectedVehicleTypeId: number = null
  _selectedVehicleIds: number[] = []
  _selectedReasonTypeId: number = null
  _startTime = ''
  _endTime = ''
  _reasonTypes: AvailabilityBlockReasonType[] = []
  _submitting = false
  _hasError = false
  _mode = ''
  _creationSource = ''

  /**
   * Gets the list of all vehicles.
   * @returns - The list of all vehicles.
   */
  get allVehicles(): TableViewVehicle[] {
    return this._allVehicles
  }

  /**
   * Gets the list of filtered vehicles.
   * @returns - The list of filtered vehicles.
   */
  get filteredVehicles(): TableViewVehicle[] {
    return this._filteredVehicles
  }

  /**
   * Gets the list of company vehicle types.
   * @returns - The list of company vehicle types.
   */
  get companyVehicleTypes(): VehicleType[] {
    return this._companyVehicleTypes
  }

  /**
   * Gets the list of all vehicle types.
   * @returns - The list of all vehicle types.
   */
  get vehicleTypes(): VehicleType[] {
    return this._vehicleTypes
  }

  /**
   * Gets the ID of the selected vehicle type.
   * @returns - The ID of the selected vehicle type.
   */
  get selectedVehicleTypeId(): number {
    return this._selectedVehicleTypeId
  }

  /**
   * Gets the list of IDs for the selected vehicles.
   * @returns - The list of IDs for the selected vehicles.
   */
  get selectedVehicleIds(): number[] {
    return this._selectedVehicleIds
  }

  /**
   * Gets the ID of the selected reason type.
   * @returns - The ID of the selected reason type.
   */
  get selectedReasonTypeId(): number {
    return this._selectedReasonTypeId
  }

  /**
   * Gets the start time for the availability block.
   * @returns - The start time for the availability block (in the format "YYYY-MM-DDTHH:MM:SS").
   */
  get startTime(): string {
    return this._startTime
  }

  /**
   * Gets the end time for the availability block.
   * @returns - The end time for the availability block (in the format "YYYY-MM-DDTHH:MM:SS").
   */
  get endTime(): string {
    return this._endTime
  }

  /**
   * Gets the list of availability block reason types.
   * @returns - The list of availability block reason types.
   */
  get reasonTypes(): AvailabilityBlockReasonType[] {
    return this._reasonTypes
  }

  /**
   * Gets a flag indicating whether the form is currently being submitted.
   * @returns - `true` if the form is being submitted, `false` otherwise.
   */
  get submitting(): boolean {
    return this._submitting
  }

  /**
   * Gets a flag indicating whether the form has an error.
   * @returns - `true` if the form has an error, `false` otherwise.
   */
  get hasError(): boolean {
    return this._hasError
  }

  /**
   * Gets the mode of the form (i.e. "create" or "edit").
   * @returns - The mode of the form.
   */
  get mode(): string {
    return this._mode
  }

  /**
   * Gets a label for the selected vehicles.
   * @returns - A label for the selected vehicles.
   */
  get getSelectedVehiclesLabel(): string {
    const aggregateSelectionThreshold = 2
    const vehicleNames = this._allVehicles
      .filter((vehicle) => this._selectedVehicleIds.includes(vehicle.vehicleId))
      .map((vehicle) => vehicle.vehicleName)
    let label = ''
    let index = 0
    for (const vehicleName of vehicleNames) {
      if (index < aggregateSelectionThreshold) {
        const appendText =
          index !== aggregateSelectionThreshold - 1 &&
          vehicleNames.length - 1 > index
            ? ', '
            : ''
        label = `${label}${vehicleName}${appendText}`
        index++
      }
    }
    if (vehicleNames.length > aggregateSelectionThreshold) {
      const othersCount = vehicleNames.length - aggregateSelectionThreshold
      label = `${label} (+${othersCount} others)`
    }
    return label
  }

  /**
   * Set the all vehicles property to the given list of vehicles.
   * @param vehicles - A list of TableViewVehicle objects.
   */
  @Mutation
  setAllVehicles(vehicles: TableViewVehicle[]): void {
    this._allVehicles = vehicles
  }
  /**
   * Set the filtered vehicles property to the given list of vehicles.
   * @param vehicles - A list of TableViewVehicle objects.
   */
  @Mutation
  setFilteredVehicles(vehicles: TableViewVehicle[]): void {
    this._filteredVehicles = vehicles
  }
  /**
   * Set the company vehicle types property to the given list of vehicle types.
   * @param vehicleTypes - A list of VehicleType objects.
   */
  @Mutation
  setCompanyVehicleTypes(vehicleTypes: VehicleType[]): void {
    this._companyVehicleTypes = vehicleTypes
  }
  /**
   * Set the vehicle types property to the given list of vehicle types.
   * @param vehicleTypes - A list of VehicleType objects.
   */
  @Mutation
  setVehicleTypes(vehicleTypes: VehicleType[]): void {
    this._vehicleTypes = vehicleTypes
  }
  /**
   * Set the selected vehicle type id property to the given vehicle type ID.
   * @param id - A vehicle type ID.
   */
  @Mutation
  setSelectedVehicleTypeId(id: number): void {
    this._selectedVehicleTypeId = id
  }
  /**
   * Set the selected vehicle ids property to the given list of vehicle IDs.
   * @param ids - A list of vehicle IDs.
   */
  @Mutation
  setSelectedVehicleIds(ids: number[]): void {
    this._selectedVehicleIds = ids
  }
  /**
   * Set the selected reason type id property to the given reason type ID.
   * @param id - A reason type ID.
   */
  @Mutation
  setSelectedReasonTypeId(id: number): void {
    this._selectedReasonTypeId = id
  }
  /**
   * Set the start time property to the given date and time.
   * @param dateTime - A date and time string in the format "YYYY-MM-DD HH:MM:SS".
   */
  @Mutation
  setStartTime(dateTime: string): void {
    this._startTime = dateTime
  }
  /**
   * Sets the end time for an availability block.
   * @param dateTime - A string representation of the end date and time.
   */
  @Mutation
  setEndTime(dateTime: string): void {
    this._endTime = dateTime
  }
  /**
   * Sets the reason types for an availability block.
   * @param reasonTypes - An array of reason types for the availability block.
   */
  @Mutation
  setReasonTypes(reasonTypes: AvailabilityBlockReasonType[]): void {
    this._reasonTypes = reasonTypes
  }
  /**
   * Sets the submitting status for an availability block.
   * @param value - A boolean representing whether the block is being submitted.
   */
  @Mutation
  setSubmitting(value: boolean): void {
    this._submitting = value
  }
  /**
   * Sets the error status for an availability block.
   * @param value - A boolean representing whether the block has an error.
   */
  @Mutation
  setHasError(value: boolean): void {
    this._hasError = value
  }
  /**
   * Sets the mode for an availability block.
   * @param mode - A string representing the mode for the availability block.
   */
  @Mutation
  setMode(mode: string): void {
    this._mode = mode
  }
  /**
   * Sets the creation source for an avaialbility block.
   * @param source - A string representing the creation source for the availability block.
   */
  @Mutation
  setCreationSource(source: string): void {
    this._creationSource = source
  }

  /**
   * Fetch all vehicles from the server and filter them by selected vehicle types.
   */
  @Action
  async fetchVehicles(): Promise<void> {
    const vehiclesListRes = await vehicle.tableView({ pageSize: -1, page: 1 })
    this.setAllVehicles(vehiclesListRes.data.resultList)
    if (this._filteredVehicles.length === 0) {
      this.setFilteredVehicles(this._allVehicles)
    }
    const vehicleTypeSet = [
      ...new Set(this._allVehicles.map((v) => v.vehicleTypeId)),
    ]
    this.setCompanyVehicleTypes(
      this._vehicleTypes.filter((vt) =>
        vehicleTypeSet.find((vts) => vts === vt.id)
      )
    )
  }

  /**
   * Fetch all vehicle types from the server.
   */
  @Action
  async fetchVehicleTypes(): Promise<void> {
    const res = await type.vehicleTypeTableView({
      pageSize: -1,
      page: 1,
    })
    this.setVehicleTypes(res.data.resultList)
  }
  /**
   * Fetch all availability block reason types from the server.
   */
  @Action
  async fetchReasonTypes(): Promise<void> {
    const res = await type.availabilityBlockReasonType()
    this.setReasonTypes(res.data.reasonTypes)
  }
  /**
   * Filters the list of vehicles based on the selected vehicle type.
   */
  @Action
  selectVehiclesByVehicleTypes(): void {
    this.setFilteredVehicles(
      this._allVehicles.filter(
        (vehicle) => vehicle.vehicleTypeId === this._selectedVehicleTypeId
      )
    )
  }
  /**
   * Update the selected vehicle type ID.
   * @param id - The ID of the selected vehicle type.
   */
  @Action
  updateSelectedVehicleTypeId(id: number): void {
    this.setSelectedVehicleTypeId(id)
  }
  /**
   * Update the selected vehicle IDs.
   * @param ids - The list of selected vehicle IDs.
   */
  @Action
  updateSelectedVehicleIds(ids: number[]): void {
    this.setSelectedVehicleIds(ids)
  }
  /**
   * Update the selected availability block reason type ID.
   * @param id - The ID of the selected availability block reason type.
   */
  @Action
  updateSelectedReasonTypeId(id: number): void {
    this.setSelectedReasonTypeId(id)
  }
  /**
   * Update the start time for the availability block.
   * @param dateTime - The start time in ISO format.
   */
  @Action
  updateStartTime(dateTime: string) {
    this.setStartTime(dateTime)
  }
  /**
   * Update the end time for the availability block.
   * @param dateTime - The end time in ISO format.
   */
  @Action
  updateEndTime(dateTime: string) {
    this.setEndTime(dateTime)
  }
  /**
   * Update the list of filtered vehicles.
   * @param vehicles - The list of filtered vehicles.
   */
  @Action
  updateFilteredVehicles(vehicles: TableViewVehicle[]) {
    this.setFilteredVehicles(vehicles)
  }
  /**
   * Update the submitting state of the form.
   * @param value - Whether the form is currently submitting or not.
   */
  @Action
  updateSubmitting(value: boolean) {
    this.setSubmitting(value)
  }
  /**
   * Update the error state of the form.
   * @param value - Whether the form has an error or not.
   */
  @Action
  updateHasError(value: boolean) {
    this.setHasError(value)
  }
  /**
   * Update the mode of the form (create or edit).
   * @param mode - The mode of the form.
   */
  @Action
  updateMode(mode: string) {
    this.setMode(mode)
  }
  /**
   * Update the creation source for the availability block.
   * @param source - The creation source.
   */
  @Action
  updateCreationSource(source: string) {
    this.setCreationSource(source)
  }
  /**
   * Reset the form to its initial state.
   */
  @Action
  reset() {
    this.updateSelectedReasonTypeId(null)
    this.updateSelectedVehicleIds([])
    this.updateFilteredVehicles(deepClone(this._allVehicles))
    this.updateStartTime('')
    this.updateEndTime('')
    this.updateCreationSource('')
  }
  /**
   * Initialize the form by fetching necessary data from the server and setting initial form values.
   * @param mode - The mode of the form (create or edit).
   */
  @Action
  async init(mode = '') {
    await Promise.all([
      this.fetchVehicleTypes(),
      this.fetchVehicles(),
      this.fetchReasonTypes(),
    ])
    this.updateSelectedVehicleTypeId(null)
    this.updateHasError(false)
    this.updateSubmitting(false)
    this.updateStartTime('')
    this.updateEndTime('')
    this.updateCreationSource('')
    this.updateMode(mode)
    this.updateSelectedReasonTypeId(null)
    this.updateSelectedVehicleIds([])
    this.updateFilteredVehicles(deepClone(this._allVehicles))
  }
  /**
   * Submit the availability block form.
   * @param availabilityBlock - The availability block to be created or edited.
   */
  @Action
  async submitAvailabilityBlock(availabilityBlock: VehicleAvailabilityBlock) {
    this.updateSubmitting(true)
    await Promise.all(
      this._selectedVehicleIds.map((vehicleId) => {
        const payload = {
          vehicleId,
          startDatetime: dayjs(this._startTime).utc(),
          endDatetime: dayjs(this._endTime).utc(),
          reasonTypeId: this._selectedReasonTypeId,
          availabilityBlockId: null,
          createdFromDateRange: false,
          createdFromRejection: false,
          createdFromMarkedSoldOut: false,
        }
        switch (this._creationSource) {
          case VehicleAvailabilityCreationSource.DateRange:
            payload.createdFromDateRange = true
            break
          case VehicleAvailabilityCreationSource.Rejection:
            payload.createdFromRejection = true
            break
          case VehicleAvailabilityCreationSource.MarkedSoldOut:
            payload.createdFromMarkedSoldOut = true
            break
          default:
            break
        }
        try {
          if (this._mode === 'edit') {
            payload.availabilityBlockId = availabilityBlock.availabilityBlockId
            availability.editVehicleBlock(payload)
          } else {
            availability.createVehicleBlock(payload)
          }
        } catch (error) {
          this.updateHasError(true)
        }
      })
    )
    this.updateSubmitting(false)
  }
  /**
   * Delete an availability block.
   * @param availabilityBlock - The availability block to be deleted.
   */
  @Action
  async deleteAvailabilityBlock(availabilityBlock: VehicleAvailabilityBlock) {
    this.updateHasError(false)
    this.updateSubmitting(true)
    const payload = {
      vehicleId: this._selectedVehicleIds[0],
      startDatetime: dayjs(this._startTime).utc(),
      endDatetime: dayjs(this._endTime).utc(),
      reasonTypeId: this._selectedReasonTypeId,
      availabilityBlockId: availabilityBlock.availabilityBlockId,
    }
    try {
      await availability.deleteVehicleBlock(payload)
    } catch (error) {
      this.updateHasError(true)
    }
    this.updateSubmitting(false)
  }
}

export default new VehicleAvailabilityModule({
  store,
  name: 'vehicleAvailability',
})
