<template>
  <v-dialog
    :value="value"
    :fullscreen="$vuetify.breakpoint.xs"
    :width="width"
    :max-width="maxWidth"
    v-bind="$attrs"
    @input="$emit('input', $event)"
  >
    <v-card class="overflow-x-hidden">
      <v-card-title
        v-if="!!$slots.title"
        :class="{
          'background-primary text-white font-medium': !informational,
          'background-white text-black font-bold border-b-0 border-transparent':
            informational,
        }"
      >
        <slot name="title" />
        <template v-if="showCloseIcon">
          <v-spacer />
          <CUIcon class="cursor-pointer" @click="$emit('input', false)">
            close
          </CUIcon>
        </template>
      </v-card-title>
      <v-card-text :class="{ 'padding-t-4': !!$slots.title }">
        <slot name="text" />
      </v-card-text>
      <v-card-actions
        v-if="!!$slots.actions"
        :style="{ 'padding-right': '25px', 'padding-left': '25px' }"
        class="padding-b-4"
      >
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Model, Prop, Vue, Component } from 'vue-property-decorator'

@Component
export default class CUModal extends Vue {
  @Model('input') readonly value!: boolean | null
  @Prop({ type: String, required: false, default: '500px' }) width!: string
  @Prop({ type: String, required: false, default: '500px' }) maxWidth!: string
  @Prop({ type: Boolean }) informational!: boolean
  @Prop({ type: Boolean }) closeable!: boolean

  get showCloseIcon(): boolean {
    return this.informational || this.closeable
  }
}
</script>
