var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.items)?_c('div',{class:{
    'v-data-table__detail': _vm.isDetailTable,
    'v-data-table__editable': _vm.isEditableTable,
    'v-data-table__mobile': _vm.isMobile,
  }},[_c('v-data-table',_vm._b({class:{ 'v-data-table__transparent-header': _vm.transparentHeaders },attrs:{"hide-default-header":_vm.hideDefaultHeader,"headers":_vm.columns,"items":_vm.items,"server-items-length":_vm.serverItemsLength,"disable-sort":"","mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds[_vm.mobileViewOnBreakpoint],"disable-filtering":"","loader-height":2,"options":_vm.options,"no-data-text":_vm.noDataText,"hide-default-footer":_vm.isDetailTable || _vm.isVehicleSync},on:{"update:options":function($event){return _vm.$emit('update:options', $event)}},scopedSlots:_vm._u([{key:"expanded-item",fn:function({ item }){return [(item.expanded)?_c(item.component,{tag:"component",attrs:{"index":item.index}}):_vm._e()]}},(!_vm.isMobile)?{key:"item",fn:function({ item, index }){return [_c('tr',{class:_vm.rowClass(item),style:({
          height: _vm.dense ? '50px !important' : '',
          cursor: _vm.clickableRow ? 'pointer' : '',
        }),on:{"click":function($event){return _vm.handleRowDetailClick(item)}}},_vm._l((_vm.visibleColumns),function(col,colIndex){return _c('td',{key:`column-${col.value}-${colIndex}-${index}`,class:[
            col.classes ? col.classes : '',
            { 'border-transparent': _vm.expandedRows.includes(index) },
          ]},[(!_vm.hideCell(col, item))?_c('CUDataTableCell',_vm._g({key:`data-table-cell-${col.value}-${colIndex}-${index}`,attrs:{"column":col,"row":item,"row-index":index,"actions":_vm.actions,"is-mobile":false,"is-detail-table":_vm.isDetailTable,"display-actions-on-mobile":_vm.displayActionsOnMobile,"detail-name":_vm.detailName,"item-key":_vm.itemKey,"table-element-id":_vm.tableElementId},on:{"expand":function($event){return _vm.handleExpand($event, index)},"row-close":function($event){return _vm.handleRowClose($event, index)},"refresh":function($event){return _vm.$emit('refresh')}}},_vm.$listeners)):_vm._e()],1)}),0)]}}:{key:"item",fn:function({ item, index }){return [_c('div',{staticClass:"d-flex border-solid border-gray-mid-light padding-a-3 border-radius-8 margin-y-3 border-1",class:{
          'padding-x-3': _vm.isDetailTable,
          'flex-row flex-wrap': _vm.isEditableTable,
          'flex-column': !_vm.isEditableTable,
        }},[_vm._l((_vm.visibleColumns),function(col,colIndex){return [(!_vm.isMobile || !_vm.hideCell(col, item))?_c('div',{key:`column-${col.value}-${colIndex}-${index}`,class:{
              'flex-basis-48-percent margin-x-1-percent':
                _vm.isEditableTable && col.type !== 'actions',
              'flex-basis-full': _vm.isEditableTable && col.type === 'actions',
            }},[(!_vm.hideCell(col, item))?_c('CUDataTableCell',_vm._g({attrs:{"column":col,"row":item,"row-index":index,"actions":_vm.actions,"is-detail-table":_vm.isDetailTable,"is-mobile":"","detail-name":_vm.detailName,"display-actions-on-mobile":_vm.displayActionsOnMobile,"item-key":_vm.itemKey},on:{"expand":function($event){return _vm.handleExpand($event, index)},"row-close":function($event){return _vm.handleRowClose($event, index)},"refresh":function($event){return _vm.$emit('refresh')}}},_vm.$listeners)):_vm._e()],1):_vm._e()]})],2)]}}],null,true)},'v-data-table',_vm.$attrs,false),[(!_vm.isDetailTable)?_c('v-pagination',{attrs:{"length":_vm.serverItemsLength},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}}):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }