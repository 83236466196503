export const bffApiUrl = '/api'

export const bffProxyUrl = `${bffApiUrl}/proxy`

export const staticUrl = `${bffApiUrl}/static`

/**
 * Returns the base URL for the static site.
 * @returns The base URL for the static site.
 */
export const staticSiteBaseUrl = (): string => {
  const hostSubdomain = window.location.host.split(':')[0].split('.')[0]
  if (hostSubdomain === 'dev') {
    return 'dev.charterup-qa.com'
  }
  return 'www.charterup.com'
}

/**
 * Returns the base URL for the operators site.
 * @returns The base URL for the operators site.
 */
export const operatorsBaseUrl = (): string => {
  const hostSubdomain = window.location.host.split(':')[0]
  if (hostSubdomain === 'localhost') {
    return 'dev.operators.charterup.com'
  }
  return hostSubdomain
}

export const splitKey = (): string => {
  const hostSubdomain = window.location.host.split(':')[0].split('.')[0]
  if (hostSubdomain === 'localhost' || hostSubdomain === 'dev') {
    return 'q65ir5g1n7pek8dn6f7rm4n0ua6patbsv69p'
  } else if (hostSubdomain === 'auto') {
    return 'q17bli4sobmq2riile3p8vm6vjdg9rkkcgr4'
  } else if (hostSubdomain === 'operators') {
    return '4mfo5hbqg0cbkifhoocbtj26jukglmlbf1o9'
  }
}

/**
 * Provides the Finix config based on the subdomain of the current host.
 * @returns an environment of either "sandbox" or "live" and and applicationId
 */
export const finixConfig = (): { environment: 'sandbox' | 'live', applicationId: string } => {

  const hostSubdomain = window.location.host.split(':')[0].split('.')[0]

  if (hostSubdomain === 'localhost' || hostSubdomain === 'dev') {
    return {
      environment: "sandbox",
      applicationId: "APigGDxtKyaBfwqMAoM2RVRB"
    }
  } else if (hostSubdomain === 'auto') {
    return {
      environment: "sandbox",
      applicationId: "APigGDxtKyaBfwqMAoM2RVRB"
    }
  } else if (hostSubdomain === 'operators') {
    return {
      environment: "live",
      applicationId: "APtkkr768DDXcQttsm2pahvz"
    }
  }

  return null
}

export const EnvironmentName = {
  unknown: 'unknown',
  local: 'local',
  dev: 'dev',
  staging: 'staging',
  production: 'production',
} as const
export type EnvironmentName = (typeof EnvironmentName)[keyof typeof EnvironmentName]
/**
 * Return the environment that this app is running in.
 */
export function resolveEnvironment(): EnvironmentName {
  // TODO: Use this everywhere we need to resolve the environment, to ensure
  // that we're using the same algorithm.
  // Use the hostname from the browser location bar.
  const browserHostname = window.location.host
  const hostParts = browserHostname.split(':')[0].split('.');
  let hostSubdomain = hostParts[0]
  // A special case - if the first element of the hostname is 'checkout', we should
  // use the second part of the hostname to determine the running environment
  if (hostSubdomain === 'checkout') {
    hostSubdomain = hostParts[1]
    if (['shofur', 'gogocharters', 'nationalbuscharter'].includes(hostSubdomain)) {
      return EnvironmentName.production
    }
  }
  if (hostSubdomain === 'localhost') {
    return EnvironmentName.local
  } else if (hostSubdomain === 'dev') {
    return EnvironmentName.dev
  } else if (hostSubdomain === 'auto') {
    return EnvironmentName.staging
  } else if (hostSubdomain === 'operators') {
    return EnvironmentName.production
  } else {
    // Couldn't determine the environment!
    console.error("Could not determine environment name from browser location " + browserHostname)
    return EnvironmentName.unknown
  }
}

/**
 * Provides the Auth0 config based on the subdomain of the current host.
 * @returns The Auth0 config based on the subdomain.
 */
export const auth0Config = (): {
  domain: string
  clientId: string
  audience: string
} => {
  const hostSubdomain = window.location.host.split(':')[0].split('.')[0]
  if (hostSubdomain === 'localhost' || hostSubdomain === 'dev') {
    return {
      domain: 'login.dev.charterup.com',
      clientId: 'nwFfrL8Wg6pFE5uGyZ9Uo4Ja94sjyRFI',
      audience: 'https://charterup-dev.us.auth0.com/api/v2/',
    }
  } else if (hostSubdomain === 'auto') {
    // TODO: update this to the correct config when we have it
    return {
      domain: 'login.auto.charterup.com',
      clientId: 'tBvxTvvA0afB4KrvoUcwwwbklolTWsQW',
      audience: 'https://charterup-staging.us.auth0.com/api/v2/',
    }
  } else if (hostSubdomain === 'operators') {
    // TODO: update this to the correct config when we have it
    return {
      domain: 'login.charterup.com',
      clientId: 'rZVatPBxKMfHAzZsGht0FN7Plkc8INJW',
      audience: 'https://charterup.us.auth0.com/api/v2/',
    }
  }

  throw new Error("Could not determine Auth0 config from browser location " + window.location.host)
}
