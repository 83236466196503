//If you write your own code, remember hex color shortcuts (eg., #fff, #000)

/**
 * Converts a hex color code to rgb color code
 *
 * @param hex - a hexadecimal color code
 * @returns the rgb color code for the provided hex color code
 */
export const hexToRgb = (hex: string): string => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  if (result) {
    const r = parseInt(result[1], 16)
    const g = parseInt(result[2], 16)
    const b = parseInt(result[3], 16)
    return `${r}, ${g}, ${b}`
  }
  return null
}
