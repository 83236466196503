/**
 * Removes a value from an array, if it exists in the array.
 *
 * @param arr - The array to modify
 * @param value - The value to remove from the array
 * @returns The modified array
 */
export const removeItem = <T>(arr: Array<T>, value: T): Array<T> => {
  const index = arr.indexOf(value)
  if (index > -1) {
    arr.splice(index, 1)
  }
  return arr
}
