<template>
  <CUSkeletonLoaderTextField v-if="skeletonLoader" :label="$attrs.label" />
  <div v-else>
    <label v-if="$attrs.label" class="font-14">
      {{ $attrs.label }}
      <span v-if="required" class="required-asterisk">*</span>
    </label>
    <slot name="label" />
    <v-text-field
      ref="v-text-field"
      :dense="dense"
      :class="{ dense: dense, finix: isFinix }"
      v-bind="$attrs"
      solo
      :label="null"
      flat
      outlined
      :append-icon="appendIcon"
      v-on="listeners"
    >
      <template v-for="(index, name) in filteredSlots" #[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
    </v-text-field>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import CUSkeletonLoaderTextField from '@/components/CUSkeletonLoaderTextField.vue'

@Component({ components: { CUSkeletonLoaderTextField } })
export default class CUTextField extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  dense!: boolean
  @Prop({
    required: false,
    default: false,
    type: Boolean,
  })
  skeletonLoader: boolean
  @Prop({ type: Boolean, required: false, default: false }) required: boolean
  @Prop({ type: String, required: false, default: '' }) appendIcon: string
  @Prop({ type: Boolean, default: false }) isFinix: boolean
  @Ref('v-text-field') readonly vTextField!: any

  get listeners(): Record<string, unknown> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this
    return Object.assign({}, this.$listeners, {
      input(event: InputEvent) {
        vm.$emit('input', event)
      },
    })
  }

  get filteredSlots(): { [key: string]: any } {
    const filteredSlots = Object.fromEntries(
      Object.entries(this.$scopedSlots).filter(([key, slot]) => key !== 'label')
    )
    return filteredSlots
  }

  validate(): boolean {
    if (this.vTextField !== undefined) {
      return this.vTextField.validate()
    }
    return false
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-text-field {
  &.dense {
    .v-input__control {
      > .v-input__slot {
        min-height: 40px !important;
      }
    }
  }
}

.required-asterisk {
  color: $error;
}
</style>
