<template>
  <div>
    <CUSkeletonLoader
      v-if="label"
      type="detail-text"
      :text="label"
      class="margin-y-1 d-inline-flex"
    />
    <CUSkeletonLoader
      v-else-if="!hideLabel"
      type="detail-text"
      width="70px"
      class="margin-y-1"
    />
    <CUSkeletonLoader type="text-field" :class="classes" />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CUSkeletonLoaderTextField extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly hideDetails: boolean
  @Prop({ type: Boolean, required: false, default: false })
  readonly hideLabel: boolean
  @Prop({ type: String, required: false, default: '' }) readonly label: string

  get classes(): Record<string, boolean> {
    return { 'margin-b-7': !this.hideDetails }
  }
}
</script>
