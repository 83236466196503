import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
dayjs.extend(duration)
dayjs.extend(utc)
interface TimeObject {
  days: number
  hours: number
  minutes: number
  seconds: number
}

/**
 * Calculates the time difference between two times as an object.
 * The returned object contains the difference in days, hours, minutes, and seconds.
 *
 * @param time1 - The first time to compare.
 * @param time2 - The second time to compare.
 * @returns An object with the difference in days, hours, minutes, and seconds.
 *
 * @example
 * import dayjs from 'dayjs'
 * import { timeDifferenceAsObject } from './timeDifferenceAsObject'
 *
 * const time1 = dayjs('2022-01-01T00:00:00Z')
 * const time2 = dayjs('2022-01-02T00:00:01Z')
 * const timeDifference = timeDifferenceAsObject(time1, time2)
 *
 * console.log(timeDifference)
 * // -> { days: 1, hours: 0, minutes: 0, seconds: 1 }
 */
export const timeDifferenceAsObject = (
  time1: dayjs.Dayjs,
  time2: dayjs.Dayjs
): TimeObject => {
  dayjs.extend(duration)
  let diff = time2.diff(time1)
  const multiplier = diff / Math.abs(diff)
  diff = diff * multiplier

  const days = dayjs.duration({ milliseconds: diff }).asDays()
  const hours = dayjs.duration({ days: days - Math.floor(days) }).asHours()
  const minutes = dayjs
    .duration({ hours: hours - Math.floor(hours) })
    .asMinutes()
  const seconds = dayjs
    .duration({ minutes: minutes - Math.floor(minutes) })
    .asSeconds()

  const roundedDays = Math.floor(days) * multiplier
  const roundedHours = Math.floor(hours) * multiplier
  const roundedMinutes = Math.floor(minutes) * multiplier
  const roundedSeconds = Math.floor(seconds) * multiplier

  return {
    days: Math.abs(roundedDays) !== 0 ? roundedDays : 0,
    hours: Math.abs(roundedHours) !== 0 ? roundedHours : 0,
    minutes: Math.abs(roundedMinutes) !== 0 ? roundedMinutes : 0,
    seconds: Math.abs(roundedSeconds) !== 0 ? roundedSeconds : 0,
  }
}

/**
 * Converts a time object to a string representation.
 * The string contains the number of days, hours, minutes, and seconds in the time object.
 * If the time object contains a value for days, the string will not include seconds.
 *
 * @param timeObj - The time object to convert to a string.
 * @returns A string representation of the time object.
 *
 * @example
 * import { timeObjectToString } from './timeObjectToString'
 *
 * const timeObj = { days: 1, hours: 0, minutes: 0, seconds: 1 }
 * const timeString = timeObjectToString(timeObj)
 *
 * console.log(timeString)
 * // -> '1d 0h 0m'
 */
export const timeObjectToString = (timeObj: TimeObject): string => {
  let timeString = `${timeObj.minutes}m`
  if (Math.abs(timeObj.hours) > 0) {
    timeString = `${timeObj.hours}h ${timeString}`
  }
  if (Math.abs(timeObj.days) > 0) {
    timeString = `${timeObj.days}d ${timeString}`
  } else {
    timeString = `${timeString} ${timeObj.seconds}s`
  }
  return timeString
}

/**
 * Calculates the time difference between two times as a string representation.
 * The string contains the difference in days, hours, minutes, and seconds.
 * If the time difference contains a value for days, the string will not include seconds.
 *
 * @param time1 - The first time to compare.
 * @param time2 - The second time to compare.
 * @returns A string representation of the time difference.
 *
 * @example
 * import dayjs from 'dayjs'
 * import { timeDifferenceAsString } from './timeDifferenceAsString'
 *
 * const time1 = dayjs('2022-01-01T00:00:00Z')
 * const time2 = dayjs('2022-01-02T00:00:01Z')
 * const timeDifference = timeDifferenceAsString(time1, time2)
 *
 * console.log(timeDifference)
 * // -> '1d 0h 0m'
 */
export const timeDifferenceAsString = (
  time1: dayjs.Dayjs,
  time2: dayjs.Dayjs
): string => {
  return timeObjectToString(timeDifferenceAsObject(time1, time2))
}

/**
 * Checks if the given timestamp is in the past.
 *
 * @param datetime - The timestamp to check.
 * @returns A boolean indicating if the timestamp is in the past.
 *
 * @example
 * import dayjs from 'dayjs'
 * import { isTimestampInPast } from './isTimestampInPast'
 *
 * const past = '2022-01-01T00:00:00Z'
 * const future = '2022-01-02T00:00:01Z'
 *
 * console.log(isTimestampInPast(past))
 * // -> true
 *
 * console.log(isTimestampInPast(future))
 * // -> false
 */
export const isTimestampInPast = (datetime: string): boolean => {
  const now = dayjs.utc()
  return dayjs(datetime).diff(now) < 0
}

/**
 * Converts hours to seconds.
 * @param hours The number of hours to convert.
 * @returns The equivalent number of seconds.
 */
export const hoursToSeconds = (hours: number): number => {
  return hours * 3600
}

/**
 * Returns the number of seconds from the current time to a specified future time in seconds.
 * @param seconds The future time in seconds.
 * @returns The number of seconds from now to the specified future time.
 */
export const secondsFromNow = (seconds: number): number => {
  return seconds - Date.now() / 1000
}

export const DAYS_IN_WEEK = 7

export const daysMap = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
}

export const abbreviatedWeekdayNames = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
]

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
