import { Action, Module, VuexModule } from 'vuex-class-modules'

// register module (could be in any file)
import store from '@/store/index'
import { Alert } from '@/models/Alert'

const DEFAULT_DURATION = 5000

@Module({ generateMutationSetters: true })
class AlertModule extends VuexModule {
  // state
  _queue: Alert[] = []
  _current: Alert = null

  /**
   * Gets the current alert.
   * @returns the current alert.
   */
  get current(): Alert {
    return this._current
  }

  // mutations (mutations are autogenerated for each root level state field)

  /**
   * Adds an alert to the queue and sets it as the current alert if there are no other alerts in the queue.
   *
   * @param {Alert} alert - The alert to add to the queue.
   * @returns {void}
   */
  @Action
  add(alert: Alert): void {
    if (!alert.hasOwnProperty('duration')) {
      alert.duration = DEFAULT_DURATION
    }
    if (!alert.hasOwnProperty('dismissible')) {
      alert.dismissible = true
    }
    this._queue.push(alert)
    if (!this._current) {
      this._current = this._queue.shift()
    }
  }

  /**
   * Removes the current alert from the queue and sets the next alert in the queue as the current alert.
   *
   * @returns {void}
   */
  @Action
  clear(): void {
    this._current = null
    if (this._queue.length) {
      this._current = this._queue.shift()
    }
  }
}

export default new AlertModule({ store, name: 'alert' })
