var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"d-inline-block"},[_c('div',{staticClass:"pie-wrapper",style:(_vm.wrapperStyles)},[(_vm.value !== null && _vm.value >= 0)?_c('span',[_c('div',{staticClass:"dot-container",style:(_vm.dotContainerStyles)},[_c('div',{staticClass:"dot",style:(_vm.dotStyles)})]),_c('div',{staticClass:"needle",style:(_vm.needleStyles)})]):_vm._e(),_c('svg',{staticClass:"position-absolute z-2",attrs:{"width":_vm.width,"height":_vm.width * 1.1}},[_c('defs',[_c('mask',{attrs:{"id":"strokeMask"}},[_c('path',{attrs:{"d":_vm.createArcPath({ start: _vm.min, end: _vm.max, color: 'black' }),"stroke-width":_vm.weight,"fill":"none","stroke":"white","stroke-linecap":"round","stroke-opacity":"100%"}})]),_vm._l((_vm.arcSegments),function(segment,segmentIndex){return _c('mask',{key:`angleMask-${segment.start}-${segmentIndex}`,attrs:{"id":`angleMask-${segmentIndex}`}},[_c('path',{attrs:{"d":_vm.createArcPath(
                  segment,
                  segmentIndex === 0 ||
                    segmentIndex === _vm.arcSegments.length - 1
                ),"fill":"white","stroke":"white","stroke-width":_vm.weight}})])}),_c('mask',{attrs:{"id":"filledArc"}},[_c('path',{attrs:{"d":_vm.createArcPath(
                  { start: _vm.min, end: _vm.needleValue, color: _vm.arcColor },
                  true
                ),"fill":"white","stroke":"white","stroke-width":_vm.weight}})])],2),_c('foreignObject',{attrs:{"width":_vm.radius * 2,"height":_vm.radius}},[_c('div',{staticClass:"border-radius-round",style:(_vm.conicalStyle)})]),_vm._l((_vm.arcSegments),function(segment,segmentIndex){return _c('svg',{key:`angleSegment-${segment.start}-${segmentIndex}-a`,attrs:{"mask":"url(#strokeMask)"}},[_c('rect',{attrs:{"x":"0","y":"0","width":"100%","height":"100%","mask":`url(#angleMask-${segmentIndex})`,"fill":segment.color,"opacity":segmentIndex === 0 || segmentIndex === _vm.arcSegments.length - 1
                ? '30%'
                : '100%'}})])}),_vm._l((_vm.arcSegments),function(segment,segmentIndex){return _c('svg',{key:`angleSegment-${segment.start}-${segmentIndex}-b`,attrs:{"mask":"url(#strokeMask)"}},[(_vm.value !== null && _vm.value >= 0)?_c('rect',{attrs:{"x":"0","y":"0","width":"100%","height":"100%","mask":"url(#filledArc)","fill":_vm.arcColor,"opacity":"100%"}}):_vm._e()])})],2),_c('div',{staticClass:"marker-label",style:(_vm.markerLabelStyles({ percent: _vm.min, color: null }))},[_vm._v(" "+_vm._s(_vm.min)+"% ")]),_vm._l((_vm.markers),function(marker,markerIndex){return [_c('div',{key:`marker-${marker.percent}-${markerIndex}`,staticClass:"marker z-20",style:(_vm.markerStyles(marker))}),_c('div',{key:`label-${marker.percent}-${markerIndex}`,staticClass:"marker-label",style:(_vm.markerLabelStyles(marker))},[_vm._v(" "+_vm._s(marker.percent)+"% ")])]}),_c('div',{staticClass:"marker-label",style:(_vm.markerLabelStyles({ percent: _vm.max, color: null }))},[_vm._v(" "+_vm._s(_vm.max)+"% ")]),_c('span',{staticClass:"score d-block text-center font-medium z-4",style:(_vm.scoreStyles)},[_vm._v(" "+_vm._s(_vm.value === null ? '--' : _vm.value)),(_vm.value !== null)?_c('span',{staticClass:"font-20"},[_vm._v("%")]):_vm._e()]),(_vm.mainLabel)?_c('span',{staticClass:"mainLabel",style:(_vm.mainLabelStyles)},[_vm._v(" "+_vm._s(_vm.mainLabel)+" ")]):_vm._e()],2)]),(_vm.secondaryLabel)?_c('span',{style:(_vm.secondaryLabelStyles)},[_vm._v(" "+_vm._s(_vm.secondaryLabel)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }