<template>
  <div
    v-if="items"
    :class="{
      'v-data-table__detail': isDetailTable,
      'v-data-table__editable': isEditableTable,
      'v-data-table__mobile': isMobile,
    }"
  >
    <v-data-table
      :hide-default-header="hideDefaultHeader"
      :class="{ 'v-data-table__transparent-header': transparentHeaders }"
      :headers="columns"
      :items="items"
      :server-items-length="serverItemsLength"
      disable-sort
      :mobile-breakpoint="
        $vuetify.breakpoint.thresholds[mobileViewOnBreakpoint]
      "
      disable-filtering
      :loader-height="2"
      v-bind="$attrs"
      :options="options"
      :no-data-text="noDataText"
      :hide-default-footer="isDetailTable || isVehicleSync"
      @update:options="$emit('update:options', $event)"
    >
      <template #expanded-item="{ item }">
        <component
          :is="item.component"
          v-if="item.expanded"
          :index="item.index"
        />
      </template>
      <template v-if="!isMobile" #item="{ item, index }">
        <tr
          :style="{
            height: dense ? '50px !important' : '',
            cursor: clickableRow ? 'pointer' : '',
          }"
          :class="rowClass(item)"
          @click="handleRowDetailClick(item)"
        >
          <td
            v-for="(col, colIndex) in visibleColumns"
            :key="`column-${col.value}-${colIndex}-${index}`"
            :class="[
              col.classes ? col.classes : '',
              { 'border-transparent': expandedRows.includes(index) },
            ]"
          >
            <CUDataTableCell
              v-if="!hideCell(col, item)"
              :key="`data-table-cell-${col.value}-${colIndex}-${index}`"
              :column="col"
              :row="item"
              :row-index="index"
              :actions="actions"
              :is-mobile="false"
              :is-detail-table="isDetailTable"
              :display-actions-on-mobile="displayActionsOnMobile"
              :detail-name="detailName"
              :item-key="itemKey"
              :table-element-id="tableElementId"
              v-on="$listeners"
              @expand="handleExpand($event, index)"
              @row-close="handleRowClose($event, index)"
              @refresh="$emit('refresh')"
            />
          </td>
        </tr>
      </template>
      <template v-else #item="{ item, index }">
        <div
          class="d-flex border-solid border-gray-mid-light padding-a-3 border-radius-8 margin-y-3 border-1"
          :class="{
            'padding-x-3': isDetailTable,
            'flex-row flex-wrap': isEditableTable,
            'flex-column': !isEditableTable,
          }"
        >
          <template v-for="(col, colIndex) in visibleColumns">
            <div
              v-if="!isMobile || !hideCell(col, item)"
              :key="`column-${col.value}-${colIndex}-${index}`"
              :class="{
                'flex-basis-48-percent margin-x-1-percent':
                  isEditableTable && col.type !== 'actions',
                'flex-basis-full': isEditableTable && col.type === 'actions',
              }"
            >
              <CUDataTableCell
                v-if="!hideCell(col, item)"
                :column="col"
                :row="item"
                :row-index="index"
                :actions="actions"
                :is-detail-table="isDetailTable"
                is-mobile
                :detail-name="detailName"
                :display-actions-on-mobile="displayActionsOnMobile"
                :item-key="itemKey"
                v-on="$listeners"
                @expand="handleExpand($event, index)"
                @row-close="handleRowClose($event, index)"
                @refresh="$emit('refresh')"
              />
            </div>
          </template>
        </div>
      </template>
      <v-pagination
        v-if="!isDetailTable"
        v-model="options.page"
        :length="serverItemsLength"
      ></v-pagination>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch, Inject } from 'vue-property-decorator'
import CUDataTableActionColumn from '@/components/CUDataTableActionColumn.vue'
import { ActionColumn } from '@/models/ActionColumn'
import CUDataTableCell from '@/components/CUDataTableCell.vue'
import { DataTableColumn } from '@/models/DataTableColumn'
import { TableViewParameters } from '@/models/TableView'
import { RawLocation } from 'vue-router'

@Component({
  components: { CUDataTableActionColumn, CUDataTableCell },
})
export default class CUDataTable extends Vue {
  @Inject({ from: 'isVehicleSync', default: false })
  readonly isVehicleSync: boolean

  @Prop({
    type: Array,
    required: false,
    default: undefined,
  })
  actions!: ActionColumn[]

  @Prop({
    type: Array,
    required: false,
    default: undefined,
  })
  items!: any[]

  @Prop({
    type: Array,
    required: true,
    default: [],
  })
  columns!: DataTableColumn[]

  @Prop({
    type: Number,
    required: true,
  })
  serverItemsLength!: number

  @Prop({
    type: Object,
    required: true,
  })
  options!: TableViewParameters

  @Prop({
    type: Boolean,
    default: false,
  })
  isDetailTable!: boolean

  @Prop({
    type: String,
    required: false,
  })
  detailName!: string
  @Prop({
    type: Boolean,
    required: false,
  })
  isEditableTable!: boolean
  @Prop({
    type: String,
    required: false,
  })
  itemKey!: string
  @Prop({
    type: String,
    required: false,
    default: 'xs',
  })
  mobileViewOnBreakpoint!: string
  @Prop({
    type: Boolean,
    required: false,
  })
  hideDefaultHeader!: boolean
  @Prop({
    type: Boolean,
    required: false,
  })
  displayActionsOnMobile!: boolean
  @Prop({
    type: String,
    required: false,
    default: 'No data found',
  })
  noDataText!: string
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  transparentHeaders!: boolean
  @Prop({
    type: String,
    required: false,
    default: 'data-table',
  })
  tableElementId!: string
  @Prop({ type: Boolean, required: false, default: false })
  dense: boolean
  @Prop({ type: String, required: false, default: '' }) uuid!: string
  @Prop({ type: Boolean, required: false, default: false })
  clickableRow: boolean
  @Prop({
    type: Function,
    required: false,
    default: () => {
      return ''
    },
  })
  rowClass!: (item: unknown) => string
  @Watch('options', {
    deep: true,
  })
  onOptionsChange(): void {
    this.expandedRows = []
  }

  expandedRows = []

  get isMobile(): boolean {
    switch (this.mobileViewOnBreakpoint) {
      case 'xs':
        return this.$vuetify.breakpoint.xs
      case 'sm':
        return this.$vuetify.breakpoint.smAndDown
      case 'md':
        return this.$vuetify.breakpoint.mdAndDown
      default:
        return false
    }
  }

  get visibleColumns(): DataTableColumn[] {
    return this.columns.filter((col) => !col.hidden)
  }

  detailRoute(row: unknown): RawLocation {
    const detailAction = this.actions.find((action) => action.key === 'details')
    if (detailAction) {
      return detailAction.detailRoute(row)
    }
    return {}
  }

  handleRowDetailClick(item: unknown): void {
    if (!this.clickableRow) {
      return
    }
    const route = this.detailRoute(item)
    if (route) {
      this.$router.push(route)
    }
  }

  handleExpand(row: unknown, rowIndex: number): void {
    this.expandedRows.push(rowIndex)
  }
  handleRowClose(row: unknown, rowIndex: number): void {
    this.expandedRows = this.expandedRows.filter((er) => er !== rowIndex)
  }
  hideCell(col, item): boolean {
    if (col.hideCell) {
      return col.hideCell(item)
    }
    return false
  }
}
</script>
