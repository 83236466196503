/* eslint-disable no-unused-vars */
export enum KeyCode {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Escape = 27,
  LeftArrow = 37,
  UpArrow = 38,
  RightArrow = 39,
  DownArrow = 40,
}

export enum ReservationType {
  CompanyOwned = 0,
  Referral = 1,
}

export enum InfoModalVariant {
  Simple = 'simple',
  Standard = 'standard',
  Itemized = 'itemized',
  Custom = 'custom',
  Payment = 'payment',
}

export enum OpportunityStatusType {
  Available = 1,
  Fulfilled = 2,
  Processing = 3,
}

export enum ReservationStatus {
  Upcoming = 'upcoming',
  Started = 'started',
  Finished = 'finished',
  Cancelled = 'cancelled',
  Hold = 'hold',
}

export enum ReferralStatus {
  Offered = 'offered',
  Reoffered = 'reoffered',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export enum PaymentStatus {
  NotPaid = 'not_paid',
  PartiallyPaid = 'partially_paid',
  FullyPaid = 'fully_paid',
}

export enum PaymentTerms {
  Net3 = 'net_3',
  Net14 = 'net_14',
  Pending = 'pending',
}

export enum BidStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export enum BidStatusId {
  Pending = 1,
  Accepted = 2,
  Rejected = 3,
}

export enum TripTypeKeys {
  OneWay = 'one_way',
  RoundTrip = 'round_trip',
  Hourly = 'hourly',
  Daily = 'daily',
  Shuttle = 'shuttle',
}

export enum TakeRate {
  Default = 10,
}

export enum EldTypeV2 {
  Samsara = 'SAMSARA',
  Motive = 'MOTIVE',
  Coachrail = 'COACHRAIL',
  Geotab = 'GEOTAB',
  Busify = 'BUSIFY',
  VerizonConnect = 'VERIZON',
  Saucon = 'SAUCON',
}

export enum CredentialsStatus {
  AccessFailure = 'ACCESS_FAILURE'
}

export enum EldType {
  Trak4 = 1,
  Samsara = 2,
  Motive = 3,
  Coachrail = 4,
  Geotab = 5,
  Busify = 6,
  VerizonConnect = 7,
  Saucon = 8,
}

export enum EldTypeKey {
  Trak4 = 'trak4',
  Samsara = 'samsara',
  Motive = 'motive',
  Geotab = 'geotab',
  VerizonConnect = 'verizonConnect',
  Saucon = 'saucon',
  Geotb = 'geotab',
}

export enum EldTypeLabel {
  Trak4 = 'Trak4',
  Samsara = 'Samsara',
  Motive = 'Motive',
  Geotab = 'Geotab',
  VerizonConnect = 'VerizonConnect',
  Saucon = 'Saucon',
  GeoTab = 'Geotab',
}

export enum MotiveClient {
  Id = '2ea8e268775927efc960a0184630a4c383c70b06dacfea39a7f7a01abc0fb157',
  Scope = 'eld_devices.read locations.vehicle_locations_list locations.vehicle_locations_single locations.driver_locations vehicles.read users.read',
}

export enum TrackingReservationStatus {
  Upcoming = 'UPCOMING',
  Started = 'STARTED',
  Finished = 'FINISHED',
}

export enum ReservationTrackingAllocation {
  All = 'ALL',
  Partial = 'PARTIAL',
  None = 'NONE',
}

export enum NoteTypeId {
  Internal = 1,
  Customer = 2,
  Driver = 3,
}

export enum ReferralRejectionReasonKey {
  NoAvailability = 'no_availability',
  IncorrectPricing = 'incorrect_pricing',
  IncorrectVehicle = 'incorrect_vehicle',
  DriverShortage = 'driver_shortage',
  DangerousRoute = 'dangerous_route',
}

export enum BookingAction {
  Accept = 'accept',
  Reject = 'reject',
}

export enum MarkupTypeId {
  VehicleType = 7,
  SPAB = 9,
  MountainTrip = 10,
  AreaOfInterest = 13,
}

export enum AreaOfInterest {
  Mountain = 1,
  Border = 2,
}

export enum PartnerTypeId {
  GoldReseller = 1,
  SilverReseller = 2,
  Platinum = 3,
  Gold = 4,
  Silver = 5,
  Bronze = 6,
  NonPartner = 7,
  CoachRailOnly = 8,
  Onboarding = 9,
  Suspended = 10,
}

export enum OnboardingStatusTypeIdTypeId {
  NotOnboarded = 1,
  CurrentlyOnboarding = 2,
  Onboarded = 5,
}

export enum AmenityTypeId {
  Wifi = 1,
  Luggage = 2,
  Bathroom = 3,
  SeatBelts = 4,
  ADACompliant = 5,
  TVScreens = 6,
  Outlets = 7,
  Leather = 8,
  CDCCompliant = 9,
  DODCompliant = 10,
  SPABCompliant = 11,
  AlcoholConsumption = 12,
}

export enum CancellationReminderDayRange {
  RequireReminder = 30,
  RequireAssignment = 2,
}

export enum TicketStatusTypeId {
  Created = 1,
  Open = 2,
  Closed = 3,
}

export enum CancellationTicketStatus {
  Requested = 'Requested',
  Cancelled = 'Cancelled',
  Denied = 'Denied',
}

export enum CancellationStatusKey {
  Requested = 'requested',
  Cancelled = 'cancelled',
  Denied = 'denied',
}

export enum CompanyId {
  CharterUP = 2,
}

export enum VehicleAvailabilityCreationSource {
  DateRange = 'dateRange',
  Rejection = 'rejection',
  MarkedSoldOut = 'markedSoldOut',
}

export enum ApplicationId {
  Busify = 4,
}

export enum RiskTypeLabel {
  IncompleteAddress = 1,
  PickupTimeMissing = 2,
  DriverHotelMissing = 3,
  FlightInfoMissing = 4,
  NonDOTCompliant = 5,
  IncompleteStop = 6,
  MissingStop = 7,
}

export enum SplitTrafficType {
  Anonymous = 'anonymous',
  Company = 'company',
  User = 'user',
}

export enum SplitKeyType {
  AnonymousCompany = 'anonymous',
}

export enum SplitFeatureFlag {
  OperatorPerformance = 'operator-performance',
  IsNew = 'is-new',
  DriverVehicleDecoupling = 'driver-vehicle-decoupling',
  OperatorAcceleratedPayments = 'operator-accelerated-payments-by-user',
  OperatorTrackingSummary = 'operator-tracking-summary-by-user',
  ShowTierBadge = 'cupops-tier-badge',
  Auth0SignIn = 'auth0-cupops'
}

export enum SplitAttribute {
  OperatorMarket = 'operator-market',
}

export enum PerformanceWidgetLabel {
  AboveTarget = 'On Target',
  Standard = 'Needs Attention',
  ActionRequired = 'Action Required',
  Calculating = 'Not Calculated',
}

export enum MetricsChipLabel {
  AboveTarget = 'On Target',
  Standard = 'Needs Attention',
  ActionRequired = 'Action Required',
}

export enum AcceptanceThreshold {
  SignificantDeltaPercentage = 0.05,
  DaysToUpdateAvailability = 6,
}

export enum FulfillmentThreshold {
  SignificantDeltaPercentage = 0.05,
  DaysToUpdateAvailability = 6,
}

export enum OnTimeThreshold {
  SignificantDeltaPercentage = 0.05,
  DaysToUpdateAvailability = 6,
}

export enum TrackingThreshold {
  SignificantDeltaPercentage = 0.05,
}

export enum EndpointValidationStatus {
  Inactive = 'INACTIVE',
  Waiting = 'WAITING',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}

export enum VINVerificationTypeKey {
  PendingValidation = 'pending_validation',
  Invalid = 'invalid',
  Valid = 'valid',
  PendingVerification = 'pending_verification',
  Verified = 'verified',
  VerificationFailure = 'verification_failure',
}

export enum VINReviewStatusKey {
  Pending = 'pending',
  Rejected = 'rejected',
  Accepted = 'accepted',
  Cancelled = 'cancelled',
}

export enum VINReviewTypeKey {
  Update = 'update',
  Create = 'create',
}

export enum TierKey {
  VIP = 'vip',
  PLUS = 'plus',
  STANDARD = 'standard',
}
